import React, { Component } from 'react';
import Dialog from '../../comps/dialog/Dialog';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPricelist, IReseller } from '../../apitypes';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { getAPICredits, findPappersCompany } from '../../api/apiModules/utils';

import { RxPencil1, RxMagnifyingGlass } from 'react-icons/rx';

export interface IEditProps {
  editResellerID: number | null,
  onCancel: Function,
  onSave: Function
}

export interface IEditState {
  pricelists: IPricelist[],
  resellerIsNew: boolean,
  reseller: IReseller | null,
  pappersEnabled: boolean
}

class EditResellerForm extends Component<IEditProps, IEditState> {

  constructor(props: IEditProps) {
    super(props);
    this.state = {
      pricelists: [],
      pappersEnabled: false,
      resellerIsNew: true,
      reseller: {
        id: -1,
        company: '',
        siret: '',
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        country: '',
        contact: '',
        email: '',
        tel: '',
        tvaintracom: '',
        naf: '',
        pricelists: []
      }
    }
  }


  async componentDidMount(): Promise<void> {
    const { editResellerID } = this.props;
    showLoading(true);
    const credits = await getAPICredits();
    console.log(`Pappers crédits: ${credits}`);
    const pappersEnabled = (credits && credits > 0) ? true : false;
    if (!pappersEnabled) {
      console.error(`Cannot fetch pappers api: no credits`);
    }
    this.setState({
      pappersEnabled,
      pricelists: await api.pricelist.getPricelists()
    });
    if (editResellerID) {
      this.setState({
        reseller: await api.reseller.getReseller(editResellerID),
        resellerIsNew: false
      })
    }
    showLoading(false);
  }


  async saveObject() {
    const { onSave } = this.props;
    const { reseller } = this.state;

    if (reseller === null) {
      return;
    }

    if (reseller.id === -1) {
      if (reseller.company === "") {
        showError(i18n.s("noResellerName"));
        return;
      }
      showLoading(true);
      const createdReseller = await api.reseller.createReseller(reseller);
      if (createdReseller) {
        onSave(createdReseller);
      }
      else {
        showError(i18n.s("resellerCreateError"));
      }
      showLoading(false);
    }
    else {
      showLoading(true);
      const updatedReseller = await api.reseller.updateReseller(reseller.id, reseller);
      if (updatedReseller) {
        onSave(updatedReseller);
      } else {
        showError(i18n.s("resellerUpdateError"));
      }
      showLoading(false);
    }
  }

  render() {
    const { reseller, pappersEnabled, pricelists, resellerIsNew } = this.state;
    const { onCancel } = this.props;

    if (reseller === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("createReseller")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        await this.saveObject();
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerSiret')}</label></div>
            <div className='input-with-button'>
              <input autoComplete='off' type="text" value={reseller.siret} placeholder={i18n.s("resellerSiret")} onChange={(e) => {
                this.setState(
                  {
                    reseller: {
                      ...reseller,
                      siret: e.currentTarget.value
                    }
                  }
                )
              }} ></input>
              {
                pappersEnabled
                  ? <button disabled={reseller.siret.trim().length < 9} className='success' onClick={async () => {
                    showLoading(true);
                    const res: any | null = await findPappersCompany(reseller.siret);
                    if (res) {
                      this.setState({
                        reseller: {
                          ...reseller,
                          siret: res.siege ? res.siege.siret : reseller.siret,
                          company: res.nom_entreprise ? res.nom_entreprise : reseller.company,
                          address1: res.siege?.adresse_ligne_1 ? res.siege?.adresse_ligne_1 : reseller.address1,
                          address2: res.siege?.adresse_ligne_2 ? res.siege?.adresse_ligne_2 : reseller.address2,
                          postalCode: res.siege?.code_postal ? res.siege?.code_postal : reseller.postalCode,
                          city: res.siege?.ville ? res.siege?.ville : reseller.city,
                          country: res.siege?.pays ? res.siege?.pays : reseller.country,
                          naf: res.code_naf ? res.code_naf : reseller.naf,
                          tvaintracom: res.numero_tva_intracommunautaire ? res.numero_tva_intracommunautaire : reseller.tvaintracom,
                          contact: res.beneficiaires_effectifs && res.beneficiaires_effectifs.length > 0 && res.beneficiaires_effectifs[0].nom !== '' ? `${res.beneficiaires_effectifs[0].nom} ${res.beneficiaires_effectifs[0].prenom}` : reseller.contact
                        }
                      })
                      showLoading(false);
                    } else {
                      showError(i18n.s("papperSearchError", ["Not Found"]));
                    }
                    showLoading(false);
                  }}><RxMagnifyingGlass /></button>
                  : null
              }
            </div>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('resellerCompany')}</label></div>
            <input autoComplete='off' type="text" value={reseller.company} placeholder={i18n.s("resellerCompany")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    company: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('resellerTVAIntracom')}</label></div>
            <input autoComplete='off' type="text" value={reseller.tvaintracom} placeholder={i18n.s("resellerTVAIntracom")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    tvaintracom: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('resellerNAF')}</label></div>
            <input autoComplete='off' type="text" value={reseller.naf} placeholder={i18n.s("resellerNAF")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    naf: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerContact')}</label></div>
            <input autoComplete='off' type="text" value={reseller.contact} placeholder={i18n.s("resellerContact")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    contact: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerAddress1')}</label></div>
            <input autoComplete='off' type="text" value={reseller.address1} placeholder={i18n.s("resellerAddress1")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    address1: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerAddress2')}</label></div>
            <input autoComplete='off' type="text" value={reseller.address2} placeholder={i18n.s("resellerAddress2")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    address2: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('postalCode')}</label></div>
            <input autoComplete='off' type="text" value={reseller.postalCode} placeholder={i18n.s("resellerPostalCode")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    postalCode: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('city')}</label></div>
            <input autoComplete='off' type="text" value={reseller.city} placeholder={i18n.s("resellerCity")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    city: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('country')}</label></div>
            <input autoComplete='off' type="text" value={reseller.country} placeholder={i18n.s("resellerCountry")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    country: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerTel')}</label></div>
            <input autoComplete='off' type="text" value={reseller.tel} placeholder={i18n.s("resellerTel")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    tel: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerEmail')}</label></div>
            <input autoComplete='off' type="text" value={reseller.email} placeholder={i18n.s("resellerEmail")} onChange={(e) => {
              this.setState(
                {
                  reseller: {
                    ...reseller,
                    email: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('resellerPricelist')}</label></div>
            <ul>
              {
                pricelists.map((pl) => {
                  return <li key={pl.id}>
                    <input type="checkbox"
                      checked={resellerIsNew || reseller.pricelists.includes(pl.id)}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          this.setState({
                            reseller: {
                              ...reseller,
                              pricelists: [
                                ...reseller.pricelists,
                                pl.id
                              ]
                            }
                          })
                        }
                        else {
                          this.setState({
                            reseller: {
                              ...reseller,
                              pricelists: reseller.pricelists.filter(epl => epl !== pl.id)
                            }
                          })
                        }
                      }}
                    />
                    <label>{pl.name}</label>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
      </div>

    </Dialog>
  }
}

export default EditResellerForm;