import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/subscription.scss';
import '../../styles/toolTip.scss';
import i18n from '../../i18n/I18n';
import { IProduct, ICustomer, ISubscription, ISubscriptionLine, IPrice, IPricelist, hasAnyPrivileges, EPrivileges, hasAllPrivileges } from '../../apitypes/index';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import EditCustomerForm from '../customerManagement/EditCustomerForm';
import Select from 'react-select';
import Dialog from '../../comps/dialog/Dialog';
import { BsPlus } from 'react-icons/bs';
import { VscTrash, VscClose } from 'react-icons/vsc';
import { RxPencil1 } from 'react-icons/rx';
import { ImCoinEuro, ImUserTie, ImWarning } from 'react-icons/im';
import { LuPackageOpen } from 'react-icons/lu';
import EditProductForm from '../ProductManagement/EditProductForm';


interface IProps {
  editSubscription: string | null,
  initialSubscriptionLines: ISubscriptionLine[], //we can set mandatory subscription line at start
  initialSubscriptionObservations: string[] | null,
  onCancel: Function,
  onSave: Function,
  onDeleted: Function,
  customer?: ICustomer | null, //customer to select or create.
}

interface IState {
  subscription: ISubscription | null,
  customers: ICustomer[],
  products: IProduct[],
  subscriptionLines: ISubscriptionLine[],
  pricelists: IPricelist[],
  prices: IPrice[] | null,
  newCustomer: ICustomer | null,
  newProduct: IProduct | null,
}

class EditSubscriptionForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      subscription: null,
      customers: [],
      products: [],
      subscriptionLines: [],
      pricelists: [],
      prices: null,
      newCustomer: null,
      newProduct: null
    }
  }

  async componentDidMount(): Promise<void> {
    const { editSubscription, initialSubscriptionLines, initialSubscriptionObservations, customer } = this.props;
    showLoading(true);

    //try to load subscription if needed
    let subscription = null;
    let subscriptionLines = null;
    if (editSubscription) { //if editSubscription is set we must load a subscription. if no this is a new Subscription
      subscription = await api.subscription.getSubscription(editSubscription);
      subscriptionLines = await api.subscription.getSubscriptionLines(editSubscription);
    }

    //do we loadan existing subscription?
    if (subscription) {
      this.setState({
        subscription: {
          ...subscription,
          observations: [
            ...subscription.observations,
            ...(initialSubscriptionObservations ? initialSubscriptionObservations : [])
          ]
        }
      })
    }
    this.setState({
      subscription: subscription ? subscription : {
        id: moment().format("YYYYMMDDHHmmssSSS"),
        date: api.unixTime(),
        customer: customer ? customer.id : -1, //if we set a default customer, set this sub to the customer
        observations: initialSubscriptionObservations ? initialSubscriptionObservations : [],
        pricelist: -1,
        validated: 0,
        canceled: 0,
        contractFile: null,
        mandatPorta: null,
        ribFile: null,
        billed: 0,
        alert: [],
        invoiceReseller: false,
      } as ISubscription,
      subscriptionLines: subscriptionLines ? [...subscriptionLines, ...initialSubscriptionLines] : [...initialSubscriptionLines],
      pricelists: [],
      prices: null,
    });

    await this.reloadCustomers();
    await this.reloadProducts();
    await this.reloadPricelists();

    showLoading(false);
  }

  async reloadCustomers() {

    const { subscription } = this.state;
    showLoading(true);

    const customers = await api.customer.getCustomers();
    this.setState({
      customers,
      newProduct: null
    });
    if (subscription?.customer) {
      this.reloadPricelists();
    }
    showLoading(false);
  }

  async reloadProducts() {

    showLoading(true);
    this.setState({
      products: await api.product.getProducts(),
      newProduct: null,
    });
    showLoading(false);
  }

  async reloadPricelists() {

    const { customers, subscription, prices } = this.state;

    if (!subscription) {
      return;
    }

    const pricelists: IPricelist[] = [];

    //load reseller
    showLoading(true);
    const customer = subscription && subscription.customer ? customers.find(c => c.id === subscription.customer) : null;
    const reseller = customer ? await api.reseller.getReseller(customer.reseller) : null;
    if (customer && reseller) {
      console.log(reseller);
      for (const plid of reseller.pricelists) {
        const pl = await api.pricelist.getPricelist(plid);
        if (pl) {
          pricelists.push(pl);
        }
      }
    }

    this.setState({
      pricelists: pricelists,
      prices: subscription.pricelist >= 0 ? await api.pricelist.getPrices(subscription.pricelist) : prices
    });
    showLoading(false);
  }


  changeSubscriptionCustomer(cust: ICustomer | null): void {
    const { subscription } = this.state;

    if (!subscription) {
      return;
    }

    this.setState({
      subscription: {
        ...subscription,
        customer: cust ? cust.id : -1
      }
    }, () => {
      this.reloadPricelists();
    })
  }

  addSubscriptionLine(productRef: string): Promise<void> {
    const { subscription, subscriptionLines, products, prices } = this.state;

    return new Promise((resolve) => {
      if (!prices) {
        showError(i18n.s('error'));
        return;
      }

      const prod = products.find(pro => pro.ref === productRef);
      if (prod && subscription) {
        let pu: Number = 0;
        let fas: Number = 0;
        let engagement: Number = 0;
        //check if specific price exists
        const price: IPrice | undefined = prices.find(p => p.product === productRef);
        if (price) {
          pu = price.pu
          fas = price.fas;
          engagement = price.engagement;
        }

        this.setState({
          subscriptionLines: [
            ...subscriptionLines,
            {
              id: uuidv4(),
              subscription: subscription.id,
              product: prod.ref,
              pu: pu,
              qty: 1,
              fas: fas,
              engagement: engagement,
              startTime: moment().unix(),
              endTime: 0
            } as ISubscriptionLine
          ],
          subscription: {
            ...subscription,
            observations: prod.noteAuto !== ""
              ? [
                ...subscription.observations
                , `${prod.name}: ${prod.noteAuto}`
              ]
              : subscription.observations
          }
        }, async () => {
          //Check if this product have dependencies
          showLoading(true);
          const dependencies = await api.product.getProductDependencies(prod.ref);
          if (dependencies) {
            for (const dep of dependencies) {
              await this.addSubscriptionLine(dep.ref);
            }
          }
          showLoading(false);
          resolve();
        });
      }
    });
  }

  changeSubscriptionLinePrice(sl: ISubscriptionLine, value: number) {
    const { prices, subscriptionLines } = this.state;

    const price = prices?.find(pr => pr.product === sl.product);
    if (price === undefined) {
      showError(i18n.s('noPriceFound'));
      return;
    }

    if (isNaN(value)) {
      value = price.pu;
    }

    if (value < 0) {
      return;
    }
    if (value < price.pu && !hasAllPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)) {
      //todo: show something that tell price is invalid !
    }
    this.setState({
      subscriptionLines: subscriptionLines.map((line: ISubscriptionLine) => {
        if (line.id !== sl.id) {
          return line;
        }
        return {
          ...line,
          pu: value
        } as ISubscriptionLine
      })
    })
  }

  changeSubscriptionLineFAS(sl: ISubscriptionLine, value: number) {
    const { prices, subscriptionLines } = this.state;

    const price = prices?.find(pr => pr.product === sl.product);
    if (price === undefined) {
      showError(i18n.s('noPriceFound'));
      return;
    }

    if (isNaN(value)) {
      value = price.fas;
    }

    if (value < 0) {
      return;
    }
    if (value < price.fas && !hasAllPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)) {
      //todo: show something that tell price is invalid !
    }
    this.setState({
      subscriptionLines: subscriptionLines.map((line: ISubscriptionLine) => {
        if (line.id !== sl.id) {
          return line;
        }
        return {
          ...line,
          fas: value
        } as ISubscriptionLine
      })
    })
  }

  changeSubscriptionQty(sl: ISubscriptionLine, value: number) {
    const { subscriptionLines } = this.state;

    if (isNaN(value) || value < 1) {
      value = 1;
    }
    this.setState({
      subscriptionLines: subscriptionLines.map((line: ISubscriptionLine) => {
        if (line.id !== sl.id) {
          return line;
        }
        return {
          ...line,
          qty: value
        } as ISubscriptionLine
      })
    })
  }

  changeSubscriptionLineEngagement(sl: ISubscriptionLine, value: number) {
    const { subscriptionLines } = this.state;

    if (isNaN(value) || value < 0) {
      value = 0;
    }
    this.setState({
      subscriptionLines: subscriptionLines.map((line: ISubscriptionLine) => {
        if (line.id !== sl.id) {
          return line;
        }
        return {
          ...line,
          engagement: value
        } as ISubscriptionLine
      })
    })
  }

  async changeSubscriptionPricelist(pl: number) {

    const { subscription, subscriptionLines } = this.state;

    if (subscription === null) {
      return;
    }

    let changePrices = subscriptionLines.length === 0;
    if (subscriptionLines.length > 0) {
      if (window.confirm(i18n.s("changePricelistConfirmation"))) {
        changePrices = true;
      }
    }

    if (changePrices) {
      showLoading(true);
      const prices = await api.pricelist.getPrices(pl)
      showLoading(false);
      //we must update our subscription lines prices
      const updatesSubscriptionLines = subscriptionLines.map((sl) => {
        const price: IPrice | undefined = prices.find(p => p.product === sl.product);
        if (price) {
          return {
            ...sl,
            pu: price.pu,
            fas: price.fas,
            engagement: price.engagement
          }
        }
        else {
          return {
            ...sl
          }
        }
      })

      this.setState({
        subscription: {
          ...subscription,
          pricelist: pl
        },
        prices: prices,
        subscriptionLines: updatesSubscriptionLines
      });
    }
  }





  render(): React.ReactNode {
    const { editSubscription, onCancel, onSave, onDeleted } = this.props;
    const { subscription, customers, subscriptionLines, products, prices, newCustomer, newProduct, pricelists } = this.state;

    if (subscription === null || api.currentUser === null || api.currentUser.reseller === null) {
      return null;
    }

    const subscriptionIsNew = editSubscription === null;

    return <Dialog
      title={i18n.s("editSubscription")}
      showOkButton={subscription.validated === 0}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        showLoading(true);
        if (subscriptionIsNew) {
          if (subscription.customer === null) {
            showError(i18n.s("noSubscriptionCustomer"));
            return;
          }

          if (subscriptionLines.length === 0) {
            showError(i18n.s("noSubscriptionLines"));
            return;
          }

          const createdSubscription = await api.subscription.createSubscription(subscription);
          if (createdSubscription) {
            const createdLines: ISubscriptionLine[] | null = await api.subscription.addOrUpdateSubscriptionLines(subscription.id, subscriptionLines)
            if (createdLines) {
              onSave(createdSubscription);
            }
            else {
              showError(i18n.s("subscriptionUpdateError"));
            }
          }
          else {
            showError(i18n.s("subscriptionCreateError"));
          }
        }
        else {
          const updatedSubscription = await api.subscription.updateSubscription(subscription.id, subscription);
          if (updatedSubscription) {
            const createdLines: ISubscriptionLine[] | null = await api.subscription.addOrUpdateSubscriptionLines(subscription.id, subscriptionLines)
            if (createdLines) {
              onSave(updatedSubscription);
            }
            else {
              showError(i18n.s("subscriptionUpdateError"));
            }
          } else {
            showError(i18n.s("subscriptionUpdateError"));
          }
        }
        showLoading(false);
      }}
      customButtons={subscriptionIsNew === false && subscription.validated === 0
        ? [
          <button className='danger' onClick={async () => {
            if (window.confirm(i18n.s("subscriptionDeleteConfirm", [subscription.id]))) {
              if (!await api.subscription.deleteSubscription(subscription.id)) {
                showError(i18n.s("subscriptionDeleteError"));
              }
              else {
                onDeleted(); //just close this form
              }
            }
          }}>
            <VscTrash />
            <label>{i18n.s('subscriptionDelete')}</label>
          </button>
        ]
        : null
      }
    >
      <div className='edit-object'>
        <h3>{i18n.s("subscriptionEdit")}</h3>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('subscriptionNumber')}</label></div>
            <input type="text" readOnly={true} value={subscription.id} />
          </div>
          <div className='row input-with-label'>
            <div><ImUserTie /> <label>{i18n.s('subscriptionCustomer')}</label></div>
            <div className='input-with-button'>
              <Select
                className='react-select'
                classNamePrefix='react-select'
                isClearable
                value={subscription.customer !== -1 ? { value: customers.find(c => c.id === subscription.customer), label: customers.find(c => c.id === subscription.customer)?.company } : null}
                onChange={(e) => {
                  const cust: ICustomer | null = e ? e.value as ICustomer : null;
                  this.changeSubscriptionCustomer(cust);
                }}
                options={customers.map((c: ICustomer) => {
                  return { value: c, label: `${c.company} (${c.siret})` }
                })}
              />

              <button className='success' disabled={hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_CUSTOMERS]) === false} onClick={() => {
                this.setState({
                  newCustomer: {
                    id: -1,
                    reseller: api.currentUser && api.currentUser.reseller ? api.currentUser.reseller : -1,
                    company: '',
                    address1: '',
                    address2: '',
                    postalCode: '',
                    city: '',
                    country: 'France',
                    siret: '',
                    contact: '',
                    email: '',
                    tel: '',
                    tvaintracom: '',
                    naf: ''
                  } as ICustomer
                })
              }}><BsPlus /></button>
            </div>
          </div>
          <div className='row input-with-label'>
            <div><ImCoinEuro /> <label>{i18n.s('invoiceTarget')}</label></div>
            <select
              value={subscription.invoiceReseller ? 'reseller' : 'customer'} onChange={async (e) => {
                this.setState({
                  subscription: {
                    ...subscription,
                    invoiceReseller: e.currentTarget.value === 'reseller'
                  }
                })
              }}>
              <option value='customer'>Facturer le client</option>
              <option value='reseller'>Facturer le revendeur</option>
            </select>
          </div>
          <div className='row input-with-label'>
            <div><ImUserTie /> <label>{i18n.s('subscriptionPricelist')}</label></div>
            <select
              value={subscription.pricelist ? subscription.pricelist : ''} onChange={async (e) => {
                this.changeSubscriptionPricelist(Number.parseInt(e.currentTarget.value));
              }}>
              <option value='-1'></option>
              {
                pricelists.map((p) => {
                  return <option key={p.id} value={p.id}>{p.name}</option>
                })
              }
            </select>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('subscriptionObservations')}</label></div>
            <textarea style={{ resize: 'vertical' }} value={subscription.observations.join('\n')} onChange={(e) => {
              this.setState(
                {
                  subscription: {
                    ...subscription,
                    observations: e.currentTarget.value.split('\n')
                  }
                }
              )
            }}></textarea>
          </div>
          <div className='row input-with-label'>
            <div><ImWarning /> <label>{i18n.s('subscriptionAlerts')}</label></div>
            <textarea style={{ resize: 'vertical' }} value={subscription.alert.join('\n')} onChange={(e) => {
              this.setState(
                {
                  subscription: {
                    ...subscription,
                    alert: e.currentTarget.value.split('\n')
                  }
                }
              )
            }}></textarea>
          </div>

          {
            newCustomer ? <EditCustomerForm
              editCustomerID={null}
              siret={null}
              onCancel={() => { this.setState({ newCustomer: null }) }}
              onSave={async (customer: ICustomer) => {
                await this.reloadCustomers();
                this.changeSubscriptionCustomer(customer);
                this.setState({
                  newCustomer: null,
                })
              }} />
              : null
          }
        </div>
        {
          subscription.pricelist > -1
            ? <Fragment>
              <h3>Produits</h3>
              <div className='row input-with-label inline'>
                {
                  subscription.validated === 0
                    ? <Fragment>
                      <div><LuPackageOpen /><label>{i18n.s('selectNewProduct')}</label></div>
                      <div className='input-with-button'>
                        <Select
                          className='react-select'
                          classNamePrefix='react-select'
                          isClearable
                          onChange={(e) => {
                            const product: IProduct | null = e ? e.value as IProduct : null;
                            if (product) {
                              if (prices) {
                                this.addSubscriptionLine(product.ref)
                              }
                              else {
                                showError(i18n.s("noPriceSelected"));
                              }
                            }
                          }}
                          options={products.filter(p => p.enabled).map((p) => {
                            return { value: p, label: `${p.name}` }
                          })}
                        />
                        <button disabled={hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN) === false} className='success' onClick={() => {
                          this.setState({
                            newProduct: {
                              ref: '',
                              name: '',
                              noteAuto: '',
                              enabled: true,
                              steps: []
                            } as IProduct
                          })
                        }}><BsPlus /></button>
                      </div>
                    </Fragment>
                    : null
                }

              </div>
              {
                newProduct ? <EditProductForm
                  editProductID={null}
                  onCancel={() => {
                    this.setState({
                      newProduct: null
                    })
                  }}
                  onSave={(product: IProduct) => {
                    this.reloadProducts();
                  }}
                /> : null
              }
              <table>
                <thead>
                  <tr>
                    {
                      subscription.validated === 0 ? <th style={{ width: '32px' }}></th> : null
                    }
                    <th>{i18n.s("subscriptionProduct")}</th>
                    <th style={{ width: "90px" }}>{i18n.s("subscriptionQTY")}</th>
                    <th style={{ width: "130px" }}>{i18n.s("subscriptionPU")}</th>
                    <th style={{ width: "130px" }}>{i18n.s("subscriptionTotal")}</th>
                    <th style={{ width: "130px" }}>{i18n.s("subscriptionFAS")}</th>
                    <th style={{ width: "130px" }}>{i18n.s("subscriptionTotalFAS")}</th>
                    <th style={{ width: "130px" }}>{i18n.s("subscriptionEngagement")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    subscriptionLines.map((sl: ISubscriptionLine) => {
                      const product = products.find(p => p.ref === sl.product);
                      return <tr key={sl.id}>
                        {
                          subscription.validated === 0
                            ? <td className='center'>
                              <button className='danger' onClick={() => {
                                if (window.confirm(i18n.s('confirmRemoveSubscriptionLine'))) {
                                  this.setState({
                                    subscriptionLines: subscriptionLines.filter(sub => sub.id !== sl.id)
                                  });
                                }
                              }}><VscClose /></button>
                            </td>
                            : null
                        }
                        <td>{product?.name}</td>
                        <td>
                          <input type='number' readOnly={subscription.validated > 0} step={1} value={sl.qty} onChange={(e) => {
                            this.changeSubscriptionQty(sl, Number.parseInt(e.currentTarget.value));
                          }} />
                        </td>
                        <td>
                          {hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.CHANGE_SUBSCRIPTION_PRICE])
                            ? <input type='number' readOnly={subscription.validated > 0} step={0.01} value={sl.pu} onChange={(e) => {
                              this.changeSubscriptionLinePrice(sl, Number.parseFloat(e.currentTarget.value));
                            }} />
                            : sl.pu}
                        </td>
                        <td className='right'>{Math.round((sl.qty * sl.pu) * 100) / 100} €</td>
                        <td>
                          {hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.CHANGE_SUBSCRIPTION_PRICE])
                            ? <input type='number' readOnly={subscription.validated > 0} step={0.01} value={sl.fas} onChange={(e) => {
                              this.changeSubscriptionLineFAS(sl, Number.parseFloat(e.currentTarget.value));
                            }} />
                            : Math.round((sl.fas) * 100) / 100}
                        </td>
                        <td className='right'>{Math.round((sl.qty * sl.fas) * 100) / 100} €</td>
                        <td>
                          {hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.CHANGE_SUBSCRIPTION_PRICE])
                            ? <input type='number' readOnly={subscription.validated > 0} step={1} value={sl.engagement} onChange={(e) => {
                              this.changeSubscriptionLineEngagement(sl, Number.parseFloat(e.currentTarget.value));
                            }} />
                            : sl.engagement}
                        </td>
                      </tr>
                    })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={subscription.validated === 0 ? 4 : 3}></th>
                    <th className='right'>{Math.round(subscriptionLines.reduce((acc: number, cur: ISubscriptionLine) => { return acc + (cur.qty * cur.pu) }, 0) * 100) / 100} €</th>
                    <th></th>
                    <th className='right'>{Math.round(subscriptionLines.reduce((acc: number, cur: ISubscriptionLine) => { return acc + (cur.qty * cur.fas) }, 0) * 100) / 100} €</th>
                    <th className='right'>{subscriptionLines.length > 0 ? [...subscriptionLines].sort((a, b) => { return b.engagement - a.engagement })[0].engagement : '-'} mois</th>
                  </tr>
                </tfoot>
              </table>
            </Fragment>
            : null
        }
      </div>
    </Dialog>
  }


}

export default EditSubscriptionForm;