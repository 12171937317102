import React, { Component, Fragment } from 'react';
import '../../styles/dialog.scss';
import { IoCloseOutline, IoCheckmarkOutline } from 'react-icons/io5';



interface IProps {
  title: string,
  autoCloseAfter?: number,
  children: React.ReactNode,
  showCloseButton?: boolean,
  showOkButton?: boolean,
  showCancelButton?: boolean,
  onClose?: Function,
  onOK?: Function,
  onCancel?: Function,
  customButtons?: Array<React.ReactNode> | null,
  width?: string,
  height?: string,
  maxWidth?: string,
  maxHeight?: string,
}

interface IState {

}


class Dialog extends Component<IProps, IState> {
  autoCloseTimer: any | null;

  constructor(props: IProps) {
    super(props);
    this.autoCloseTimer = null;
  }

  componentDidMount(): void {
    const { autoCloseAfter, onClose } = this.props;
    if (autoCloseAfter && autoCloseAfter > 0) {
      this.autoCloseTimer = setTimeout(() => {
        return onClose ? onClose() : null;
      }, autoCloseAfter);
    }
  }

  componentWillUnmount(): void {
    if (this.autoCloseTimer !== null) {
      clearTimeout(this.autoCloseTimer);
    }
  }

  render() {
    const {
      children, title,
      showOkButton, showCancelButton, showCloseButton,
      onClose, onCancel, onOK,
      customButtons,
      maxWidth, maxHeight,
      width, height
    } = this.props;

    return (
      <Fragment>
        <div className='dialog-outer'></div>
        <div className='dialog'>
          <div className='dialog-inner' style={{
            maxWidth: maxWidth ? `${maxWidth}px` : undefined,
            maxHeight: maxHeight ? `${maxHeight}px` : undefined,
            width: width === undefined ? "auto" : width,
            height: height === undefined ? "auto" : height
          }}>
            <div className='dialog-title'>
              <span>{title}</span>
              {
                showCloseButton
                  ? <button className='close-button' onClick={() => {
                    if (this.autoCloseTimer !== null) {
                      clearTimeout(this.autoCloseTimer);
                    }
                    return onClose ? onClose() : null;
                  }}><IoCloseOutline /></button>
                  : null
              }
            </div>
            <div className='dialog-content'>
              {children}
            </div>
            <div className='dialog-buttons'>
              {
                customButtons
                  ? customButtons.map((cb) => {
                    return cb;
                  })
                  : null
              }
              {
                showCancelButton
                  ? <button className='danger' onClick={() => {
                    if (this.autoCloseTimer !== null) {
                      clearTimeout(this.autoCloseTimer);
                    }
                    return onCancel ? onCancel() : null;
                  }}>
                    <IoCloseOutline />
                    <label>Annuler</label>
                  </button>
                  : null
              }
              {
                showOkButton
                  ? <button className='success' onClick={() => {
                    if (this.autoCloseTimer !== null) {
                      clearTimeout(this.autoCloseTimer);
                    }
                    return onOK ? onOK() : null;
                  }}>
                    <IoCheckmarkOutline />
                    <label>Valider</label>
                  </button>
                  : null
              }
            </div>
          </div>
        </div>
      </Fragment>

    );
  }
}

export default Dialog;