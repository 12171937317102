import { GetAPI, UploadAPI, PutAPI, DeleteAPI, IUploadFile } from './http';
import { IFile } from '../../apitypes/index';

export default class FileModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }

  /**
   * File api: 
   * get directory tree (directories and subdirectories recursively)
   * 
   * @param initialPath string initial path, can be empty to start at root. do NOT add initial slash ! Ex: path/to/directory. 
   * @returns array of directories a
   */
  async getDirTree(initialPath: string): Promise<IFile | null> {
    let response = await GetAPI(`${this.baseUri}/files/dirtree/${initialPath}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  /**
   * File api: 
   * get files and directories . not recursive
   * Note: the uri to downlaod the file will ALWAY be window.location.host/fichiers/path/to/file.ext
   * so the servert MUST serve files directly.
   * 
   * @param initialPath string initial path, can be empty to start at root. do NOT add initial slash ! Ex: path/to/directory. 
   * @returns array of directories/files
   */
  async getFileList(initialPath: string): Promise<IFile[]> {
    let response = await GetAPI(`${this.baseUri}/files/listfiles/${initialPath}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  /**
   * File api: 
   * Search files and directories . Recursive
   * 
   * @param search string the search value 
   * @returns array of directories/files
   */
  async findFiles(search: string): Promise<IFile[]> {
    let response = await GetAPI(`${this.baseUri}/files/find/${search}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }



  /**
   * File api: 
   * Delete file
   * 
   * @param path path of file/directory to delete
   * @returns boolean|string true if file was deleted, or the error message
   */
  async deleteFile(path: string): Promise<boolean | string> {
    let response = await DeleteAPI(`${this.baseUri}/files/delete/${path}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return msg.error;
    }
  }


  /**
   * File api: 
   * Create a folder
   * 
   * @param path path of folder to create
   * @returns boolean|string true if directory was created, or the error message
   */
  async createDirectory(path: string): Promise<boolean | string> {
    let response = await PutAPI(`${this.baseUri}/files/mkdir/${path}`, this.token, {}, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return msg.error;
    }
  }

  /**
   * File api: 
   * Upload files
   * 
   * @param path path where to upload files
   * @param files files to upload
   * @returns boolean|string true if files were uploaded, or the error msg
   */
  async uploadFiles(path: string, files: Array<File>, onProgressEvent: Function): Promise<boolean | string> {
    console.log(files);

    const uploadFiles = [];
    for (let i = 0; i < files.length; i++) {
      uploadFiles.push({ type: i.toString(), file: files[i] } as IUploadFile);
    }

    let response = await UploadAPI(`${this.baseUri}/files/upload/${path}`, this.token, uploadFiles, onProgressEvent, this.onAuthErrorCallback);
    const msg = JSON.parse(response);
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return msg.error;
    }
  }

  async copyFileTo(source: string, destination: string, move: boolean = false): Promise<boolean | string> {
    let response = await PutAPI(`${this.baseUri}/files/copy`, this.token, {
      source,
      destination
    }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      if (move) {
        if (await this.deleteFile(source) === false) {
          await this.deleteFile(destination);
          return false;
        }
        return true;
      }
      return true;
    }
    else {
      console.error(msg.error);
      return msg.error;
    }
  }
}




