import React, { Component, Fragment } from 'react';
import i18n from '../../i18n/I18n';
import "../../styles/login.scss";
import { RiUserSmileLine, RiLockPasswordLine } from 'react-icons/ri';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import API from '../../api/API';
import { IUser } from '../../apitypes/index';

interface IProps {
  onAuth: Function,
  lastLogin: string,
  persist: boolean,
  logo?: React.ReactNode
}

interface IState {
  username: string,
  password: string,
  error: string,
  info: string,
  showResetPasswordForm: boolean,
  persist: boolean,
  loading: boolean
}

class Login extends Component<IProps, IState> {
  timerInfo: any = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      username: props.lastLogin,
      password: '',
      error: '',
      info: '',
      showResetPasswordForm: false,
      persist: props.persist,
      loading: false,
    }

    this.timerInfo = null;
  }


  async setStateAsync(newState: any) {
    return new Promise((resolve) => {
      this.setState({
        ...this.state,
        ...newState
      }, () => {
        resolve(null);
      });
    });
  }

  async tryLogin() {
    const { username, password, persist } = this.state;
    await this.setStateAsync({ error: '', loading: true });
    const user: IUser | null = await api.auth.login(username, password);
    if (user !== null) {
      const { onAuth } = this.props;
      onAuth(user, persist);
      this.setState({
        error: '',
        info: '',
        loading: false,
      })
    }
    else {
      this.setState({
        error: i18n.s("authError"),
        loading: false,
      });
    }
  }

  async tryLogout() {
    await api.auth.logout();
  }


  async resetPassword() {
    const { username } = this.state;
    if (this.timerInfo !== null) {
      return;
    }
    this.setState({
      error: '',
      loading: true,
    });

    const success = await api.auth.lostPassword(username);
    if (success) {
      this.setState({
        info: i18n.s("passwordResetEmailSent"),
        error: '',
        showResetPasswordForm: false,
        loading: false,
      });
      this.timerInfo = setTimeout(() => {
        this.setState({
          info: '',
          loading: false,
        });
        this.timerInfo = null;
      }, 30000)
    }
    else {
      this.setState({
        error: i18n.s("passwordResetEmailError"),
        info: '',
        loading: false,
      });
    }
  }

  render() {
    const {
      logo
    } = this.props;
    const {
      username,
      password,
      error,
      info,
      persist,
      showResetPasswordForm,
      loading
    } = this.state;

    return (
      <div id='login-form'>
        {
          logo
            ? <div className='login-logo'>{logo}</div>
            : null
        }


        {
          showResetPasswordForm === false
            ? <Fragment>
              <div className='input-with-label'>
                <div>
                  <RiUserSmileLine /><label>{i18n.s("login")}</label>
                </div>
                <input type="text" value={username} placeholder={i18n.s("login")} onChange={(e) => { this.setState({ username: e.currentTarget.value }) }} />
              </div>
              <div className='input-with-label'>
                <div><RiLockPasswordLine /> <label>{i18n.s("password")}</label></div>
                <input type="password" value={password} placeholder={i18n.s("password")} onChange={(e) => { this.setState({ password: e.currentTarget.value }) }} />
              </div>
              <div style={{ alignSelf: "flex-start" }}>
                <input type="checkbox" checked={persist ? true : false} onChange={(e) => { this.setState({ persist: e.target.checked }) }} />
                {i18n.s("persistLogin")}
              </div>
              <div className='buttons'>
                <button className='success' onClick={() => { this.tryLogin() }}>{i18n.s("connect")}</button>
                <button onClick={() => {
                  this.setState({
                    info: i18n.s("howCreateAccount")
                  })
                }}>{i18n.s("createAccount")}</button>
              </div>
              <div className='div-password-lost'>
                <label className='action-label' onClick={() => {
                  this.setState({
                    showResetPasswordForm: true
                  })
                }}>{i18n.s("passwordLost")}</label>
              </div>
            </Fragment>
            : <Fragment>
              <div className='input-with-label'>
                <div>
                  <RiUserSmileLine /><label>{i18n.s("login")}</label>
                </div>
                <input type="text" value={username} placeholder={i18n.s("login")} onChange={(e) => { this.setState({ username: e.currentTarget.value }) }} />
              </div>
              <div className='buttons'>
                <button className='success' onClick={() => { this.resetPassword() }}>{i18n.s("sendResetPassword")}</button>
                <button className='warning' onClick={() => {
                  this.setState({
                    showResetPasswordForm: false
                  })
                }}>{i18n.s("cancel")}</button>
              </div>
            </Fragment>
        }



        {
          error !== ''
            ? <div className='error-box'>{error}</div>
            : null
        }
        {
          info !== ''
            ? <div className='info-box'>{info}</div>
            : null
        }

        {loading ? <div className='loading'>
          <label>{i18n.s("loading")}</label>
        </div> : null}

      </div>
    );
  }
}

export default Login;