import React, { Component } from 'react';
import Dialog from '../../comps/dialog/Dialog';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { ICustomer, IReseller, EPrivileges, hasAnyPrivileges } from '../../apitypes';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { getAPICredits, findPappersCompany } from '../../api/apiModules/utils';

import { RxPencil1, RxMagnifyingGlass } from 'react-icons/rx';
import { ImUserTie } from 'react-icons/im';
import { BsPlus } from 'react-icons/bs';
import EditResellerForm from '../resellerManagement/EditResellerForm';

export interface IEditProps {
  editCustomerID: number | null,
  siret: string | null,
  onCancel: Function,
  onSave: Function
}

export interface IEditState {
  resellers: IReseller[],
  customer: ICustomer | null,
  pappersEnabled: boolean,
  newReseller: IReseller | null
}

class EditCustomerForm extends Component<IEditProps, IEditState> {

  constructor(props: IEditProps) {
    super(props);
    this.state = {
      resellers: [],
      pappersEnabled: false,
      newReseller: null,
      customer: null
    }
  }


  async componentDidMount(): Promise<void> {
    const { editCustomerID, siret } = this.props;
    showLoading(true);

    const credits = await getAPICredits();
    console.log(`Pappers crédits: ${credits}`);
    const pappersEnabled = (credits && credits > 0) ? true : false;
    if (!pappersEnabled) {
      console.error(`Cannot fetch pappers api: no credits`);
    }
    this.setState({
      customer: editCustomerID ? await api.customer.getCustomer(editCustomerID) : {
        id: -1,
        //we load a customer or create a new one.
        reseller: api.currentUser && api.currentUser.reseller ? api.currentUser.reseller : -1,
        company: '',
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        country: 'France',
        siret: siret ? siret : '',
        contact: '',
        email: '',
        tel: '',
        tvaintracom: '',
        naf: ''
      },
      pappersEnabled,
    });
    await this.loadResellers();
    showLoading(false);
  }

  async loadResellers() {

    showLoading(true);
    this.setState({
      resellers: await api.reseller.getResellers(),
      newReseller: null,
    });
    showLoading(false);
  }

  async saveObject() {
    const { onSave } = this.props;
    const { customer } = this.state;

    if (customer === null) {
      return;
    }

    if (customer.id === -1) {
      if (customer.reseller === -1) {
        showError(i18n.s("noResellerSet"));
        return;
      }
      if (customer.company === "") {
        showError(i18n.s("noCustomerCompany"));
        return;
      }
      if (customer.reseller === -1) {
        showError(i18n.s("noCustomerReseller"));
        return;
      }
      const newCustomer = await api.customer.createCustomer(customer);
      if (newCustomer) {
        onSave(newCustomer);
      }
      else {
        showError(i18n.s("customerCreateError"));
      }
    }
    else {
      showLoading(true);
      const updatedCustomer = await api.customer.updateCustomer(customer.id, customer);
      if (updatedCustomer) {
        onSave(updatedCustomer);
      } else {
        showError(i18n.s("customerUpdateError"));
      }
      showLoading(false);
    }
  }


  render() {
    const { customer, pappersEnabled, newReseller, resellers } = this.state;
    const { onCancel } = this.props;

    if (customer === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("createNewCustomer")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        await this.saveObject();
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><ImUserTie /> <label>{i18n.s('customerReseller')}</label></div>
            <div className='input-with-button'>
              <select
                value={customer.reseller ? customer.reseller : ""}
                onChange={(e) => {
                  this.setState(
                    {
                      customer: {
                        ...customer,
                        reseller: Number.parseInt(e.currentTarget.value)
                      }
                    }
                  )
                }}
              >
                <option value='-1'></option>
                {
                  resellers.map((res) => {
                    return <option key={res.id} value={res.id}>{res.company}</option>
                  })
                }
              </select>
              <button
                disabled={hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN]) === false}
                onClick={() => {
                  this.setState({
                    newReseller: {
                      id: -1,
                      company: '',
                      siret: '',
                      address1: '',
                      address2: '',
                      postalCode: '',
                      city: '',
                      country: '',
                      contact: '',
                      email: '',
                      tel: '',
                      tvaintracom: '',
                      naf: '',
                      pricelists: []
                    }
                  })
                }} className='success'>
                <BsPlus />
              </button>
            </div>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerSiret')}</label></div>
            <div className='input-with-button'>
              <input autoComplete='off' type="text" value={customer.siret} placeholder={i18n.s("customerSiret")} onChange={(e) => {
                this.setState(
                  {
                    customer: {
                      ...customer,
                      siret: e.currentTarget.value
                    }
                  }
                )
              }} ></input>
              {
                pappersEnabled
                  ? <button disabled={customer.siret.trim().length < 9} onClick={async () => {
                    showLoading(true);
                    const res: any | null = await findPappersCompany(customer.siret);
                    if (res) {
                      this.setState({
                        customer: {
                          ...customer,
                          siret: res.siege ? res.siege.siret : customer.siret,
                          company: res.nom_entreprise ? res.nom_entreprise : customer.company,
                          address1: res.siege?.adresse_ligne_1 ? res.siege?.adresse_ligne_1 : customer.address1,
                          address2: res.siege?.adresse_ligne_2 ? res.siege?.adresse_ligne_2 : customer.address2,
                          postalCode: res.siege?.code_postal ? res.siege?.code_postal : customer.postalCode,
                          city: res.siege?.ville ? res.siege?.ville : customer.city,
                          country: res.siege?.pays ? res.siege?.pays : customer.country,
                          naf: res.code_naf ? res.code_naf : customer.naf,
                          tvaintracom: res.numero_tva_intracommunautaire ? res.numero_tva_intracommunautaire : customer.tvaintracom,
                          contact: res.beneficiaires_effectifs && res.beneficiaires_effectifs.length > 0 && res.beneficiaires_effectifs[0].nom !== '' ? `${res.beneficiaires_effectifs[0].nom} ${res.beneficiaires_effectifs[0].prenom}` : customer.contact
                        }
                      })
                    }
                    else {
                      showError(i18n.s("papperSearchError", ["Not found"]));
                    }
                    showLoading(false);
                  }}><RxMagnifyingGlass /></button>
                  : null
              }

            </div>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerCompany')}</label></div>
            <input autoComplete='off' type="text" value={customer.company} placeholder={i18n.s("customerCompany")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    company: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerTVAIntracom')}</label></div>
            <input autoComplete='off' type="text" value={customer.tvaintracom} placeholder={i18n.s("customerTVAIntracom")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    tvaintracom: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerNAF')}</label></div>
            <input autoComplete='off' type="text" value={customer.naf} placeholder={i18n.s("customerNAF")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    naf: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerContact')}</label></div>
            <input autoComplete='off' type="text" value={customer.contact} placeholder={i18n.s("customerContact")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    contact: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerAddress1')}</label></div>
            <input autoComplete='off' type="text" value={customer.address1} placeholder={i18n.s("customerAddress1")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    address1: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerAddress2')}</label></div>
            <input autoComplete='off' type="text" value={customer.address2} placeholder={i18n.s("customerAddress2")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    address2: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('postalCode')}</label></div>
            <input autoComplete='off' type="text" value={customer.postalCode} placeholder={i18n.s("postalCode")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    postalCode: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('city')}</label></div>
            <input autoComplete='off' type="text" value={customer.city} placeholder={i18n.s("city")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    city: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('country')}</label></div>
            <input autoComplete='off' type="text" value={customer.country} placeholder={i18n.s("country")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    country: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerTel')}</label></div>
            <input autoComplete='off' type="text" value={customer.tel} placeholder={i18n.s("customerTel")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    tel: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('customerEmail')}</label></div>
            <input autoComplete='off' type="text" value={customer.email} placeholder={i18n.s("customerEmail")} onChange={(e) => {
              this.setState(
                {
                  customer: {
                    ...customer,
                    email: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
        </div>

        {newReseller ? <EditResellerForm editResellerID={null} onCancel={() => { this.setState({ newReseller: null }) }} onSave={async (reseller: IReseller) => {
          await this.loadResellers();
          this.setState({
            customer: {
              ...customer,
              reseller: reseller.id
            }
          })
        }} /> : null}
      </div>
    </Dialog>
  }
}

export default EditCustomerForm;