const storage = (function () {
  //Load the storage. by default, load the session storaghe
  var storageType: string = "session";
  var storage: Storage = sessionStorage;
  //if the localstorage exist, we load the localstorage
  if (localStorage.getItem("persist") !== null) {
    storage = localStorage;
    storageType = "local";
  }

  const _public = {
    //Convert a sessionstorage to a localstorage
    persist: (): void => {
      if (storage === null) {
        throw new Error("Storage is null");
      }
      if (storageType === "session") {
        //Create new storage
        var newStorage = localStorage;
        //clear it in case it already has data
        newStorage.clear();
        //set persistence in this storage
        newStorage.setItem("persist", "true");
        //copy all data from session storage to this new storage
        for (var i = 0; i < storage.length; i++) {
          let key = storage.key(i);
          if (key !== null) {
            let value = storage.getItem(key);
            if (value !== null) {
              newStorage.setItem(key, value);
            }
          }
        }
        //clear old storage
        storage.clear();
        //switch to new storage
        storage = newStorage;
      }
      else {
        console.error("Cannot set storage persistence: it's already a persistent storage");
      }
    },

    isPersistent: () => {
      return storageType === "local";
    },

    setValue: (name: string, data: string | number | object): void => {
      var strData: string = "";
      switch (typeof data) {
        case "string":
          strData = data;
          break;
        case "number":
          strData = data.toString();
          break;
        case "object":
          strData = JSON.stringify(data);
          break;
        default:
          throw new Error("Unknow type " + typeof (data));
      }
      storage.setItem(name, strData);
      console.log(`Stored item ${name}: ${strData}`);
    },

    getString: (name: string): string => {
      const tmp = storage.getItem(name);
      return tmp !== null ? tmp : "";
    },

    getNumber: (name: string): number => {
      const tmp = storage.getItem(name);
      return tmp !== null && tmp !== "" ? Number.parseFloat(tmp) : Number.NaN;
    },

    getObject: (name: string): object => {
      const tmp = storage.getItem(name);
      return tmp !== null && tmp !== "" ? JSON.parse(tmp) : null;
    },

    deleteValue: (name: string): void => {
      storage.removeItem(name);
      console.log(`Removed ${name} from storage`);
    },
  }

  return _public;
})();

export default storage;