import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { IAdresseCommune, ICommune, IImmeuble } from '../../apitypes/index';

export default class EligibilityModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getCommunes(): Promise<ICommune[]> {
    let response = await GetAPI(`${this.baseUri}/eligibility/communes`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getAdressesCommune(codePostal: string, commune: string): Promise<IAdresseCommune[]> {
    let response = await GetAPI(`${this.baseUri}/eligibility/adresses/${codePostal}/${commune}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getImmeubles(minLong: number, maxLong: number, minLat: number, maxLat: number, start: number, count: number): Promise<IImmeuble[]> {
    let response = await GetAPI(`${this.baseUri}/eligibility/immeubles/${minLong}/${maxLong}/${minLat}/${maxLat}/${start}/${count}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }



  async getEntreprisesNearPoint(lat: number, long: number, radius: number): Promise<any[]> {
    let response = await GetAPI(`${this.baseUri}/eligibility/entreprises/nearpoint/${lat}/${long}/${radius}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getEntreprisesByAdresse(adresse: string): Promise<any[]> {
    let response = await GetAPI(`${this.baseUri}/eligibility/entreprises/byadresse/${adresse}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

}




