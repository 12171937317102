import React, { Component } from 'react';
import Dialog from '../../comps/dialog/Dialog';
import { IReseller, IUser, hasAnyPrivileges, EPrivileges, ENotifications } from '../../apitypes';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { VscMail, VscCheckAll } from 'react-icons/vsc';
import { ImUserTie } from 'react-icons/im';
import { MdOutlineRule } from 'react-icons/md';
import Select from 'react-select';


interface IProps {
  editUserID: number | null,
  onCancel: Function,
  onSave: Function
}

interface IState {
  user: IUser | null,
  resellers: IReseller[],
  error: string | null,
  loading: boolean,
}

class EditUserForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      user: null,
      resellers: [],
      error: null,
      loading: true,
    }
  }


  async componentDidMount(): Promise<void> {
    const { editUserID } = this.props;
    const resellers = await api.reseller.getResellers()

    const user = editUserID !== null ? await api.user.getUser(editUserID) : {
      id: -1,
      email: '',
      reseller: -1,
      cgvSeen: null,
      privileges: [],
      notifications: []
    };
    this.setState({
      resellers,
      user,
      loading: false
    })
  }


  async saveObject() {
    const { onSave } = this.props;
    const { user } = this.state;

    if (user === null) {
      return;
    }

    if (user.id === -1) {
      if (user.email === "") {
        this.setState({
          error: i18n.s("noUserEmail")
        });
        return;
      }
      const createdUser = await api.user.createUser(user);
      if (createdUser) {
        onSave(createdUser);
      }
      else {
        this.setState({
          error: i18n.s("userCreateError")
        });
      }
    }
    else {
      const updatedUser = await api.user.updateUser(user.id, user);
      if (updatedUser) {
        onSave(updatedUser);
      }
      else {
        this.setState({
          error: i18n.s("userUpdateError")
        });
      }
    }
  }

  async addPrivilege(ep: EPrivileges): Promise<void> {
    switch (ep) {
      case EPrivileges.WRITE_CUSTOMERS:
        await this.addPrivilege(EPrivileges.READ_CUSTOMERS);
        break;
      case EPrivileges.WRITE_FILES:
        await this.addPrivilege(EPrivileges.READ_FILES);
        break;
      case EPrivileges.WRITE_PRICELIST:
        await this.addPrivilege(EPrivileges.READ_PRODUCTS);
        await this.addPrivilege(EPrivileges.READ_PRICELIST);
        break;
      case EPrivileges.WRITE_PRODUCTS:
        await this.addPrivilege(EPrivileges.READ_PRODUCTS);
        break;
      case EPrivileges.WRITE_RESELLERS:
        await this.addPrivilege(EPrivileges.READ_PRICELIST);
        await this.addPrivilege(EPrivileges.READ_RESELLERS);
        break;
      case EPrivileges.WRITE_SUBSCRIPTIONS:
        await this.addPrivilege(EPrivileges.READ_CUSTOMERS);
        await this.addPrivilege(EPrivileges.READ_PRODUCTS);
        await this.addPrivilege(EPrivileges.READ_SUBSCRIPTIONS);
        break;
      case EPrivileges.WRITE_USERS:
        await this.addPrivilege(EPrivileges.READ_USERS);
        break;
      case EPrivileges.READ_XORCOM_LICENSES:
        await this.addPrivilege(EPrivileges.READ_RESELLERS);
        await this.addPrivilege(EPrivileges.READ_CUSTOMERS);
        break;
      case EPrivileges.WRITE_XORCOM_LICENSES:
        await this.addPrivilege(EPrivileges.READ_RESELLERS);
        await this.addPrivilege(EPrivileges.READ_CUSTOMERS);
        await this.addPrivilege(EPrivileges.READ_XORCOM_LICENSES);
        break;
      case EPrivileges.WRITE_BAREMETAL:
        await this.addPrivilege(EPrivileges.READ_BAREMETAL);
        await this.addPrivilege(EPrivileges.READ_VMS);
        await this.addPrivilege(EPrivileges.WRITE_VMS);
        break;
      case EPrivileges.WRITE_VMS:
        await this.addPrivilege(EPrivileges.READ_VMS);
        break;
      default: break;
    }

    //On récupère l'objet a editer APRES avoir éventuellement ajouté les références. sinon on modifie l'objet initial et pas celui ou on a ajouté les dépendences
    const { user } = this.state;
    if (!user) {
      return;
    }


    if (user.privileges.find(pri => pri === ep)) {
      return; //already there !
    }

    this.setState({
      user: {
        ...user,
        privileges: [
          ...user.privileges,
          ep
        ]
      }
    })
  }

  async removePrivilege(ep: EPrivileges) {
    const { user } = this.state;
    if (!user) {
      return;
    }

    this.setState({
      user: {
        ...user,
        privileges: user.privileges.filter(pri => pri !== ep)
      }
    })
  }


  render() {
    const { user, resellers, loading } = this.state;
    const { onCancel } = this.props;

    if (user === null) {
      return null;
    }

    const notifications = Object.values(ENotifications);

    return <Dialog
      title={i18n.s("createUser")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        this.saveObject();
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><VscMail /> <label>{i18n.s('email')}</label></div>
            <input autoComplete='off' type="text" value={user.email} onChange={(e) => {
              this.setState(
                {
                  user: {
                    ...user,
                    email: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><ImUserTie /> <label>{i18n.s('resellerName')}</label></div>
            <select value={user !== null && user.reseller !== null ? user.reseller : ""} onChange={(e) => {
              this.setState(
                {
                  user: {
                    ...user,
                    reseller: e.currentTarget.value !== "" ? Number.parseInt(e.currentTarget.value) : -1
                  }
                }
              )
            }} >
              {
                hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
                  ? <option value=''></option>
                  : null
              }

              {
                resellers.map((r) => {
                  return <option key={r.id} value={r.id}>{r.company}</option>
                })
              }
            </select>
          </div>
          {
            hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
              ? <div className='row input-with-label'>
                <div><MdOutlineRule /> <label>{i18n.s('userPrivileges')}</label></div>
                <ul>
                  {
                    Object.values(EPrivileges).map((ep) => {
                      return <li>
                        <input type="checkbox"
                          value={ep} checked={user.privileges.findIndex(pri => pri === ep) > -1}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              this.addPrivilege(ep);
                            }
                            else {
                              this.removePrivilege(ep);
                            }
                          }}
                        />
                        <label>{i18n.s(ep)}</label>
                      </li>
                    })
                  }
                </ul>
                <div className='buttons'>
                  <button onClick={() => {
                    this.setState(
                      {
                        user: {
                          ...user,
                          privileges: Object.values(EPrivileges).filter((ep) => { return ep !== "SUPER_ADMIN" }).map((ep) => { return ep })
                        }
                      }
                    )
                  }}><VscCheckAll /><label>{i18n.s('allButSUPER_ADMIN')}</label></button>
                </div>

              </div>
              : null
          }
          <div className='row input-with-label'>
            <div><ImUserTie /> <label>{i18n.s('userNotifications')}</label></div>
            <Select
              className='react-select'
              classNamePrefix='react-select'
              isMulti
              value={
                user.notifications.map((n) => {
                  return { value: ENotifications[n], label: i18n.s(n) }
                })}
              onChange={(e) => {
                this.setState({
                  user: {
                    ...user,
                    notifications: e.map(option => option.value)
                  }
                })
              }}
              options={
                notifications.map((n) => {
                  return { value: ENotifications[n], label: i18n.s(n) }
                })
              }
              menuPlacement='top'
            />
            <div className='buttons'>
              <button onClick={() => {
                this.setState(
                  {
                    user: {
                      ...user,
                      notifications: Object.values(ENotifications).map((ep) => { return ep })
                    }
                  }
                )
              }}><VscCheckAll /><label>{i18n.s('allNotifications')}</label></button>
            </div>
          </div>
        </div>

      </div>

      {loading ? <div className='loading' ></div> : null}
    </Dialog >
  }
}

export default EditUserForm;