import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/dhcpManagement.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { IDHCPEntry } from '../../apitypes/index';

import { VscSearch, VscRefresh } from 'react-icons/vsc';
import { IoTerminalSharp } from "react-icons/io5";
import { BsBrowserEdge } from "react-icons/bs";

interface IState {
  search: string,
  dhcpEntries: IDHCPEntry[],
  sortBy: string,
  sortOrder: E_SORTORDER
}

class DHCPManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      dhcpEntries: [],
      sortBy: 'lastSeen',
      sortOrder: E_SORTORDER.ASC
    }
  }


  getDuration(sec: number): string {
    let min = 0;
    let hours = 0;
    let days = 0;
    while (sec >= 60) {
      min++;
      sec -= 60;
      if (min === 60) {
        hours++;
        min = 0;
      }
      if (hours === 24) {
        days++;
        hours = 0;
      }
    }
    return `${days}j, ${hours < 10 ? "0" + hours : hours}h${min < 10 ? "0" + min : min}m${sec < 10 ? "0" + sec : sec}s`;
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  sortObjectsBy(sortBy: string) {
    const { dhcpEntries, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      dhcpEntries: sortObjects(dhcpEntries, sortBy, newSortOrder)
    });
  }

  async reloadObjects() {

    const { sortBy, sortOrder } = this.state;
    showLoading(true);
    this.setState({
      dhcpEntries: sortObjects(await api.dhcp.getDHCPEntries(), sortBy, sortOrder)
    });
    showLoading(false);
  }


  renderObjectRow(obj: IDHCPEntry): React.ReactNode {
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (obj.mac.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        return null;
      }
    }

    return <tr key={obj.mac} >
      <td>{obj.mac}</td>
      <td>{obj.ip}</td>
      <td className='dhcp-link'>
        <span>
          <IoTerminalSharp />
          <a href={`ssh://${obj.ip}`} target='_blank' rel='noreferrer'>ssh</a>
        </span>
        <span>
          <BsBrowserEdge />
          <a href={`http://${obj.ip}:80`} target='_blank' rel='noreferrer'>http</a>
        </span>
        <span>
          <BsBrowserEdge />
          <a href={`https://${obj.ip}:443`} target='_blank' rel='noreferrer'>https</a>
        </span>
      </td>
      <td>{obj.vendor}</td>
      <td>{obj.hostname}</td>
      <td>{obj.status}</td>
      <td>{obj.dynamic ? i18n.s('yes') : i18n.s('no')}</td>
      <td>{this.getDuration(obj.expire)}</td>
      <td>{this.getDuration(obj.lastSeen)}</td>
      <td>{obj.comment}</td>
    </tr>
  }





  render() {
    const { dhcpEntries, search } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("DHCPExplorer")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <button title={i18n.s("reload")} onClick={() => { this.reloadObjects() }}><VscRefresh /></button>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "130px" }} onClick={() => { this.sortObjectsBy("mac") }}>{i18n.s("mac")}</th>
                  <th style={{ width: "100px" }} onClick={() => { this.sortObjectsBy("ip") }}>{i18n.s("ip")}</th>
                  <th style={{ width: "180px" }} >{i18n.s("quickAccess")}</th>
                  <th onClick={() => { this.sortObjectsBy("vendor") }}>{i18n.s("vendor")}</th>
                  <th onClick={() => { this.sortObjectsBy("hostname") }}>{i18n.s("hostname")}</th>
                  <th style={{ width: "100px" }} onClick={() => { this.sortObjectsBy("status") }}>{i18n.s("status")}</th>
                  <th style={{ width: "100px" }} onClick={() => { this.sortObjectsBy("dynamic") }}>{i18n.s("dynamic")}</th>
                  <th style={{ width: "130px" }} onClick={() => { this.sortObjectsBy("expire") }}>{i18n.s("expire")}</th>
                  <th style={{ width: "130px" }} onClick={() => { this.sortObjectsBy("lastSeen") }}>{i18n.s("lastSeen")}</th>
                  <th onClick={() => { this.sortObjectsBy("comment") }}>{i18n.s("comment")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  dhcpEntries.map((obj) => {
                    return this.renderObjectRow(obj)
                  })
                }
              </tbody>
            </table>
          </div>


        </div>
      </Fragment>

    );
  }
}

export default DHCPManagement;