interface ILanguage {
  code: string,
  name: string,
  strings: any
}


/**
 * Internationalisation super simplifiée pour TS
 * 
 */
class I18n {
  languages: ILanguage[];
  curLang: ILanguage | null;

  private unresolvedKeys: string[];

  constructor() {
    this.languages = [];
    this.curLang = null;

    this.unresolvedKeys = [];
  }

  /**
   * Utiliser cette méthode pour charger les chaines de caractères d'une langue
   * Les langues sont dans des fichiers json à changer comme un module. 
   * il faut donc charger toutes les langues avant le début du programme.
   * 
   * @param langObject ILanguage objet de définition de la langue à charger.
   * @returns 
   */
  loadLanguageDefinition(langObject: ILanguage): boolean {
    console.log(`i18n - Loading ${langObject.code} ${langObject.name}`);
    if (this.languages.findIndex((l: ILanguage): boolean => {
      return l.code === langObject.code
    }) === -1) {
      this.languages = [
        ...this.languages,
        langObject
      ]
      if (this.curLang === null) {
        this.setLanguage(langObject.code);
      }
      return true;
    }
    return false;
  }


  /**
   * Change la langue sélectionnée. 
   * 
   * @param code string code à deux lettres de la langue
   * @returns 
   */
  setLanguage(code: string): boolean {
    let lang = this.languages.find((l: ILanguage): boolean => {
      return l.code === code;
    });

    if (lang !== undefined) {
      this.curLang = lang;
      return true;
    }
    return false;
  }

  /**
   * Retourne une chaine de caractères dans la bonne langue.
   * Il est possible de passer un tableau avec une liste de valeurs à placer dans la chaine de caractère. les %s sont remplacés par ces valeurs.
   * 
   * @param key string la clef de la chaine de caractère à retourner
   * @param replace Array<string> valeurs à placer dans la chaine de caractère
   */
  s(key: string, replace: string[] = []): string {
    if (this.curLang === null) {
      return key;
    }
    if (key in this.curLang.strings) {
      var str = this.curLang.strings[key];
      for (const rep of replace) {
        str = str.replace("%s", rep);
      }
      return str;
    }

    if (this.unresolvedKeys.indexOf(key) === -1) {
      this.unresolvedKeys.push(key);
      this.showUnresolvedKeys();
    }
    return key;
  }


  showUnresolvedKeys() {
    let allocate = "";
    for (var s of this.unresolvedKeys) {
      allocate += `"${s}": "",\r\n`;
    }
    console.log(`Unresolved i18n keys found. Please allocate these keys in your json language file: \r\n${allocate}`);
  }
}
const instance = new I18n();
export default instance;