import { Fragment, useEffect, useState } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/eligibilite.scss';
import { IPageProps } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import { ICommune, IAdresseCommune, IImmeuble, ISubscription, ICustomer, hasAnyPrivileges, EPrivileges } from '../../apitypes/index';
import i18n from '../../i18n/I18n';
import { RxPencil1 } from 'react-icons/rx';
import WindowedSelect, { createFilter } from "react-windowed-select";
import { MapContainer, TileLayer, useMapEvent, useMap, Circle } from 'react-leaflet';
import { Map } from 'leaflet';
import { AiOutlineCopy, AiOutlineLoading } from "react-icons/ai";
import EditSubscriptionForm from '../SubscriptionManagement/EditSubscriptionForm';
import Dialog from '../../comps/dialog/Dialog';
import EditCustomerForm from '../customerManagement/EditCustomerForm';


interface ISelectOption {
  value: any,
  label: string
}



let timeoutUpdateImmeuble: any = null;
const maxResults = 10000;
let map: Map | null = null;

export default function Eligbility(props: IPageProps) {
  const [mounted, setMounted] = useState<boolean>(false);
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [commune, setCommune] = useState<ICommune | null>(null);
  const [adresses, setAdresses] = useState<IAdresseCommune[]>([]);
  const [adresse, setAdresse] = useState<IAdresseCommune | null>(null);
  const [mapPoint, setMapPoint] = useState<[number, number] | null>(null);

  const [immeubles, setImmeubles] = useState<IImmeuble[]>([]);
  const [immeuble, setImmeuble] = useState<IImmeuble | null>(null);
  const [immeubleInfoPos, setImmeubleInfoPos] = useState<[number, number] | null>(null);
  const [entreprisesLoading, setEntreprisesLoading] = useState<boolean>(false);
  const [entreprises, setEntreprises] = useState<any[] | null>(null);
  const [showEntreprises, setShowEntreprises] = useState<boolean>(false);
  const [newSubscriptionCustomerSiret, setNewSubscriptionCustomerSiret] = useState<string | null>(null);
  const [customer, setCustomer] = useState<ICustomer | null>(null);

  const [mapInitOK, setMapInitOK] = useState<boolean>(false);
  const [detailledResults, setDetailledResults] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);

  const [createSubscription, setCreateSubscription] = useState<boolean>(false);

  //Load communes the first time
  useEffect(() => {
    console.log(mounted);
    if (mounted === false) {
      (async () => {
        showLoading(true);
        setCommunes(await api.eligbility.getCommunes());
        showLoading(false);
      })();
    }
    setMounted(true);
  }, [api, mounted]);

  useEffect(() => {
    (async () => {
      if (map && commune) {
        showLoading(true);
        const adressesCommune = await api.eligbility.getAdressesCommune(commune?.codePostal, commune?.commune);
        showLoading(false);
        setAdresses(adressesCommune);
        setAdresse(null);
        setMapPoint(null);
        if (adressesCommune.length > 0) {
          map.setView([adressesCommune[0].y, adressesCommune[0].x], 16);
        }
      }
      else {
        setAdresses([]);
        setAdresse(null);
        setMapPoint(null);
      }
    })();
  }, [api, commune]);

  useEffect(() => {
    (async () => {
      if (map && adresse) {
        map.setView([adresse.y, adresse.x], 18);
        setMapPoint([adresse.y, adresse.x]);
      }
    })();
  }, [adresse])

  useEffect(() => {
    (async () => {
      if (immeuble && (immeuble.imbEtat === "deploye" || immeuble.imbEtat === "raccordable demande")) {
        //try to get entreprises
        let adresse = immeuble.numVoie ? immeuble.numVoie : "";
        adresse += immeuble.cpNoVoie ? ` ${immeuble.cpNoVoie}` : "";
        adresse += immeuble.typeVoie ? ` ${immeuble.typeVoie}` : "";
        adresse += immeuble.nomVoie ? ` ${immeuble.nomVoie}` : "";
        adresse += immeuble.codePostal ? ` ${immeuble.codePostal}` : "";
        adresse += immeuble.commune ? ` ${immeuble.commune}` : "";
        setShowEntreprises(false);
        setEntreprises(null);
        setEntreprisesLoading(true);
        const entreprises = await api.eligbility.getEntreprisesByAdresse(adresse);
        setEntreprises(entreprises);
        setEntreprisesLoading(false);
      }
    })();
  }, [api, immeuble]);

  const getColorStatusImmeuble = (imm: IImmeuble): [string, string] => {
    let color = "grey";
    let etat = i18n.s("eligibilityUnknown");

    if (detailledResults) {
      switch (imm.imbEtat) {
        case "abandonne":
          color = "black";
          etat = i18n.s("eligibilityAbandonne");
          break;
        case "signe":
          color = "red";
          etat = i18n.s("eligibilitySigne");
          break;
        case "cible":
          color = "orange";
          etat = i18n.s("eligibilityCible");
          break;
        case "en cours de deploiement":
          color = "yellow";
          etat = i18n.s("eligibilityEnCoursDeDeploiement");
          break;
        case "deploye":
          color = "green";
          etat = i18n.s("eligibilityDeploye");
          break;
        case "raccordable demande":
          color = "blue";
          etat = i18n.s("eligibilityRaccordableDemande");
          break;
        default:
          color = "grey";
          etat = i18n.s("eligibilityUnknown");
          break;
      }
    }
    else {
      switch (imm.imbEtat) {
        case "deploye":
        case "raccordable demande":
          color = "green";
          etat = i18n.s("eligibilityDeploye");
          break;
        default:
          color = "red";
          etat = i18n.s("eligibilityNotDeploye");
          break;
      }
    }

    return [color, etat];
  }


  const updateImmeubles = async (map: Map) => {
    setImmeubles([]);
    clearTimeout(timeoutUpdateImmeuble);
    timeoutUpdateImmeuble = setTimeout(async () => {
      const zoom = map.getZoom();
      if (zoom >= 18) {
        showLoading(true);
        const bound = map.getBounds();
        const minLong = bound.getSouthWest().lng;
        const minLat = bound.getSouthWest().lat;
        const maxLong = bound.getNorthEast().lng;
        const maxLat = bound.getNorthEast().lat;
        let imms = await api.eligbility.getImmeubles(minLong, maxLong, minLat, maxLat, 0, maxResults);
        imms = imms.sort((a, b) => {
          if (a.nomVoie.toLowerCase().trim() === b.nomVoie.toLowerCase().trim()) {
            if (a.numVoie === "" && b.numVoie !== "") {
              return -1;
            }
            else if (a.numVoie !== "" && b.numVoie === "") {
              return 1
            }
            else {
              return parseInt(a.numVoie) - parseInt(b.numVoie);
            }
          }
          return a.nomVoie.toLowerCase().trim().localeCompare(b.nomVoie.toLowerCase().trim())
        })
        setImmeubles(imms);

        showLoading(false);
        if (imms.length === maxResults) {
          showError(i18n.s("eligibilityPleaseZoom"));
        }
      }
      else {
        setImmeubles([]);
        showError(i18n.s("eligibilityPleaseZoom"));
      }
    }, 100);
  }


  const MapEvents = () => {
    map = useMap();

    useMapEvent('locationfound', (e) => {
      if (map) {
        map.stopLocate();
        const center = e.bounds.getCenter();
        map.setView(center, 18)
        setImmeuble(null);
        updateImmeubles(map);
      }

    });

    useMapEvent('movestart', (e) => {
      if (map) {
        setImmeuble(null);
      }
    });

    useMapEvent('moveend', (e) => {
      if (map) {
        setImmeuble(null);
        updateImmeubles(map);
      }
    });

    if (map && mapInitOK === false) {
      map.locate();
      setMapInitOK(true);
    }

    return null;
  }


  const ImmeubleInfo = () => {
    if (immeuble === null) {
      return null;
    }
    console.log(immeuble);

    const pos = immeubleInfoPos ? immeubleInfoPos : [0, 0];

    const [color, etat] = getColorStatusImmeuble(immeuble);
    return <div className='immeuble-info' style={{ left: pos[0] + 5, top: pos[1] + 5 }}>
      <div className='info'>
        <label>ID immeuble</label>
        <label>{immeuble.imbID}</label>
        <label><button onClick={async () => {
          await navigator.clipboard.writeText(immeuble.imbID);
          showInfo(i18n.s("clipboardCopied"));
        }}><AiOutlineCopy /></button></label>
      </div>
      <div className='info'>
        <label>{i18n.s("eligibilityNumStreet")}</label>
        <label>{`${immeuble.numVoie} ${immeuble.cpNoVoie}`}</label>
      </div>
      <div className='info'>
        <label>{i18n.s("eligibilityStreet")}</label>
        <label>{`${immeuble.typeVoie} ${immeuble.nomVoie}`}</label>
      </div>
      <div className='info'>
        <label>{i18n.s("eligibilityBat")}</label>
        <label>{immeuble.batiment}</label>
      </div>
      <div className='info'>
        <label>{i18n.s("eligibilityPostalCode")}</label>
        <label>{immeuble.codePostal}</label>
      </div>
      <div className='info'>
        <label>{i18n.s("eligibilityCity")}</label>
        <label>{immeuble.commune}</label>
      </div>
      <div className='info'>
        <label>{i18n.s("eligibilityStatus")}</label>
        <label>
          <div className='status' style={{ backgroundColor: color }}></div>
          <div>{etat}</div>
        </label>
      </div>
      {
        detailledResults
          ? <Fragment>
            <div className='info'>
              <label>{i18n.s("eligibilityOI")}</label>
              <label>{immeuble.codeL331}</label>
            </div>
            <div className='info'>
              <label>{i18n.s("latitude")}</label>
              <label>{immeuble.lat}</label>
              <label><button onClick={async () => {
                await navigator.clipboard.writeText(immeuble.lat.toString());
                showInfo(i18n.s("clipboardCopied"));
              }}><AiOutlineCopy /></button></label>
            </div>
            <div className='info'>
              <label>{i18n.s("longitude")}</label>
              <label>{immeuble.long}</label>
              <label><button onClick={async () => {
                await navigator.clipboard.writeText(immeuble.long.toString());
                showInfo(i18n.s("clipboardCopied"));
              }}><AiOutlineCopy /></button></label>
            </div>
            <div className='info'>
              <label>{i18n.s("eligibilityTypeImmeuble")}</label>
              <label>{immeuble.typeImb}</label>
            </div>
            <div className='info'>
              <label>{i18n.s("eligibilityCatalogLocImb")}</label>
              <label>{immeuble.catalogLocImb}</label>
            </div>
            <div className='info'>
              <label>{i18n.s("eligibilityRefPM")}</label>
              <label>{immeuble.pmRef}</label>
            </div>
            <div className='info'>
              <label>{i18n.s("eligibilityDateCompletude")}</label>
              <label>{immeuble.dateCompletude}</label>
            </div>
          </Fragment>
          : null
      }
      <div className='disclaimer'>
        {i18n.s("eligibilityDisclaimer")}
      </div>
      <div className='buttons'>
        {
          hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_SUBSCRIPTIONS, EPrivileges.ELIGIBILITY]) && entreprisesLoading
            ? <button disabled={true}>
              <label className='rotate'><AiOutlineLoading /></label>
              <label>{i18n.s("eligibilityEntreprisesLoading")}</label>
            </button>
            : null
        }
        {
          hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_SUBSCRIPTIONS, EPrivileges.ELIGIBILITY]) && entreprises && entreprises.filter(ent => ent.date_fermeture).length > 0
            ? <button onClick={() => { setShowEntreprises(true); }}>
              <label>{i18n.s("eligibilitySeeEntreprises")}</label>
            </button>
            : null
        }
        {
          hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_SUBSCRIPTIONS, EPrivileges.ELIGIBILITY]) && immeuble.imbEtat === "deploye"
            ? <button className='success' onClick={() => {
              setNewSubscriptionCustomerSiret(null);
              setCreateSubscription(true);
            }}>{i18n.s("eligibilityCreateSubscription")}</button>
            : null
        }
        <button className='danger' onClick={() => { setImmeuble(null) }}>{i18n.s("close")}</button>
      </div>
    </div>
  }



  return (
    <div className='management eligibilite'>
      <h1>{i18n.s("eligibilityAddress")}</h1>
      <div className='form'>
        <div className='inline'>
          <div className='input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('eligibilitySearchCommune')}</label></div>
            <WindowedSelect
              filterOption={createFilter({ ignoreAccents: false })}
              windowThreshold={20}
              className='react-select'
              classNamePrefix='react-select'
              value={commune ? { value: commune, label: `${commune.codePostal} ${commune.commune}` } : null}
              onChange={(newValue) => {
                setCommune(newValue ? (newValue as ISelectOption).value as ICommune : null);
              }}
              options={communes ? communes.map((c) => { return { value: c, label: `${c.codePostal} ${c.commune}` } }) : []}
            />
          </div>
          <div className='input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('eligibilitySearchAddress')}</label></div>
            <WindowedSelect
              filterOption={createFilter({ ignoreAccents: false })}
              windowThreshold={20}
              className='react-select'
              classNamePrefix='react-select'
              value={adresse ? { value: adresse, label: `${adresse.numVoie} ${adresse.cpNoVoie} ${adresse.typeVoie} ${adresse.nomVoie}` } : null}
              onChange={(newValue) => {
                setAdresse(newValue ? (newValue as ISelectOption).value as IAdresseCommune : null);
              }}
              options={adresses ? adresses.map((ad) => { return { value: ad, label: `${ad.numVoie} ${ad.cpNoVoie} ${ad.typeVoie} ${ad.nomVoie}` } }) : []}
            />
          </div>
        </div>
        <div className='filter'>
          <div>
            <input type="checkbox" checked={detailledResults} onChange={(e) => { setDetailledResults(e.currentTarget.checked) }} />
            <label>{i18n.s("eligibilityDetailledResults")}</label>
          </div>
          <div>
            <input type="checkbox" checked={showTable} onChange={(e) => { setShowTable(e.currentTarget.checked) }} />
            <label>{i18n.s("eligibilityShowTable")}</label>
          </div>
        </div>
      </div>


      <div className='eligibility-results'>
        <MapContainer className='map' center={[46.5, 3]} zoom={7} scrollWheelZoom={true} maxZoom={18} >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapEvents />

          {
            mapPoint
              ? <Circle center={[mapPoint[0], mapPoint[1]]} radius={10} pathOptions={{ fill: false }} />
              : null
          }

          {
            immeubles ? immeubles.map((imm) => {
              const [color, etat] = getColorStatusImmeuble(imm);
              return <Circle
                key={imm.imbID}
                center={[imm.lat, imm.long]}
                pathOptions={{ stroke: true, color: "#333", weight: 1, fill: true, fillColor: color, fillOpacity: 1, bubblingMouseEvents: false }}
                radius={2}
                eventHandlers={{
                  click: (e) => {
                    setImmeubleInfoPos([e.containerPoint.x, e.containerPoint.y]);
                    setImmeuble(imm);
                  },
                }}
              />
            })
              : null
          }
        </MapContainer>
        {
          immeuble
            ? <ImmeubleInfo />
            : null
        }


        {
          showTable
            ? <div className='table'>
              <table>
                <thead>
                  <tr>
                    <th>Adresse</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    immeubles.map((imm: IImmeuble) => {
                      const [color, etat] = getColorStatusImmeuble(imm);
                      return <tr>
                        <td>{`${imm.numVoie} ${imm.cpNoVoie} ${imm.typeVoie} ${imm.nomVoie}`}</td>
                        <td>
                          <label className='status' style={{ backgroundColor: color, marginRight: "5px" }}></label>
                          <label>{etat}</label></td>
                      </tr >
                    })
                  }
                </tbody>
              </table>
            </div>
            : null
        }
      </div>


      {
        showEntreprises && entreprises && immeuble
          ?
          <Dialog
            title={i18n.s('entreprisesList')}
            showCloseButton={true}
            showOkButton={true}
            onClose={() => { setShowEntreprises(false) }}
            onOK={() => { setShowEntreprises(false) }}
          >
            <div className='entreprises'>
              <table>
                <thead>
                  <tr>
                    <th>{i18n.s('SIRET')}</th>
                    <th>{i18n.s('company')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    entreprises.map((ent: any) => {
                      if (ent.date_fermeture) {
                        return null;
                      }
                      if (ent.matching_etablissements.length > 0) {
                        return <tr>
                          <td >
                            <div className='inline'>
                              <button onClick={async () => {
                                await navigator.clipboard.writeText(ent.matching_etablissements[0].siret);
                                showInfo(i18n.s("clipboardCopied"));
                              }}><AiOutlineCopy /></button>
                              <label>{ent.matching_etablissements[0].siret}</label>
                            </div>
                          </td>
                          <td>
                            <div className='inline'>
                              <button className='success' onClick={() => {
                                setShowEntreprises(false);
                                setNewSubscriptionCustomerSiret(ent.matching_etablissements[0].siret);
                              }}>{i18n.s("eligibilityCreateSubscription")}</button>
                              <label>{ent.nom_complet}</label>
                            </div>
                          </td>
                        </tr>
                      }
                      return null;
                    })
                  }
                </tbody>
              </table>
            </div>
          </Dialog>
          : null
      }




      {
        newSubscriptionCustomerSiret
          ? <div style={{ zIndex: 9000 }}>
            <EditCustomerForm
              editCustomerID={null}
              siret={newSubscriptionCustomerSiret}
              onCancel={() => { setNewSubscriptionCustomerSiret(null); setCreateSubscription(false) }}
              onSave={(cust: ICustomer) => { setCustomer(cust); setCreateSubscription(true) }}
            />
          </div>
          : null
      }


      {
        createSubscription && immeuble
          ? <div style={{ zIndex: 9000 }}>
            <EditSubscriptionForm
              editSubscription={null}
              customer={customer}
              initialSubscriptionLines={[]}
              initialSubscriptionObservations={[`Code immeuble FO: ${immeuble.imbID}`]}
              onCancel={() => { setNewSubscriptionCustomerSiret(null); setCreateSubscription(false) }}
              onDeleted={() => { setNewSubscriptionCustomerSiret(null); setCreateSubscription(false) }}
              onSave={() => { setNewSubscriptionCustomerSiret(null); setCreateSubscription(false) }}
            />
          </div>
          : null
      }


    </div >
  );
}


