import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { EPrivileges, ICustomer, IReseller, hasAnyPrivileges } from '../../apitypes/index';
import Dialog from '../../comps/dialog/Dialog';
import EditCustomerForm from './EditCustomerForm';
import EditResellerForm from '../resellerManagement/EditResellerForm';

import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';


interface IState {
  search: string,
  customers: ICustomer[],
  resellers: IReseller[],
  showDeleteDialog: boolean,
  editObject: ICustomer | null,
  createNew: boolean,
  createReseller: boolean,
  selectedIDs: number[],
  sortBy: string,
  sortOrder: E_SORTORDER,
}

class CustomerManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      customers: [],
      resellers: [],
      showDeleteDialog: false,
      editObject: null,
      createNew: false,
      createReseller: false,
      selectedIDs: [],
      sortBy: '',
      sortOrder: E_SORTORDER.ASC
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  sortObjectsBy(sortBy: string) {
    const { customers, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      customers: sortObjects(customers, sortBy, newSortOrder)
    });
  }


  async reloadObjects() {

    showLoading(true);
    this.setState({
      customers: await api.customer.getCustomers(),
      editObject: null,
      createNew: false,
      showDeleteDialog: false,
      selectedIDs: [],
    });
    await this.reloadResellers();
    showLoading(false);
  }


  async reloadResellers() {


    showLoading(true);
    this.setState({
      resellers: await api.reseller.getResellers()
    });
    showLoading(false);
  }





  renderDeleteDialog() {
    const { selectedIDs } = this.state;
    return <Dialog
      title={i18n.s("deleteCustomerConfirm")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          showDeleteDialog: false
        })
      }}
      onOK={async () => {
        showLoading(true);
        for (var id of selectedIDs) {
          if (await api.customer.deleteCustomer(id)) {
            showSuccess(i18n.s('success'));
          }
          else {
            showError(i18n.s('error'));
          }
        }
        this.reloadObjects();
        showLoading(false);
      }}
    >
      <div>
        <label>{i18n.s("deleteCustomerConfirmString", [selectedIDs.length.toString()])}</label>
      </div>
    </Dialog>
  }



  renderObjectRow(obj: ICustomer): React.ReactNode {
    const { selectedIDs, resellers } = this.state;

    const currentReseller = resellers.find(r => r.id === obj.reseller);
    const isSelected = selectedIDs.findIndex(suid => suid === obj.id) > -1;
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (obj.company.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.address2.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.address2.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.postalCode.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.city.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.country.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.contact.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.email.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.tel.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.siret.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.tvaintracom.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.naf.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        if (selectedIDs.indexOf(obj.id) !== -1) {
          this.setState({
            selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
          });
        }
        return null;
      }
    }

    return <tr key={obj.id} >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          disabled={obj.openSubscriptionCount && obj.openSubscriptionCount > 0 ? true : undefined}
          checked={isSelected}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              this.setState({
                selectedIDs: [
                  ...selectedIDs,
                  obj.id
                ]
              })
            }
            else {
              this.setState({
                selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
              })
            }
          }}
        />
      </td>
      <td><button onClick={() => { this.setState({ editObject: obj }) }}><VscEdit /></button></td>
      <td >{currentReseller ? currentReseller.company : ''}</td>
      <td>
        {
          obj.siret && obj.siret.length >= 9 ? <a target='_blank' rel="noreferrer" href={`https://www.pappers.fr/entreprise/${obj.siret.substring(0, 9)}`}>{obj.company}</a> : obj.company
        }
      </td>
      <td>{obj.siret}</td>
      <td>{obj.postalCode}</td>
      <td>{obj.city}</td>
      <td>{obj.country}</td>
      <td>{obj.contact}</td>
      <td>{obj.email}</td>
      <td>{obj.tel}</td>
      <td>{obj.openSubscriptionCount ? obj.openSubscriptionCount : 0}</td>
    </tr >
  }





  render() {
    const { customers, search, editObject, createNew, selectedIDs, showDeleteDialog, createReseller } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("customers")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <button onClick={() => { this.reloadObjects() }}><VscRefresh /></button>
            {
              hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_CUSTOMERS])
                ? <Fragment>
                  <button className='success' title={i18n.s('createCustomer')} onClick={() => {
                    this.setState({
                      editObject: null,
                      createNew: true,
                    })
                  }}><BsPlus /></button>
                  <button className='danger' title={i18n.s('deleteCustomers')} disabled={selectedIDs.length === 0} onClick={() => {
                    this.setState({
                      showDeleteDialog: true
                    })
                  }}><AiOutlineDelete /></button>
                </Fragment>
                : null
            }
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "30px", textAlign: "center" }}>
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (e.currentTarget.checked) {
                          this.setState({
                            selectedIDs: customers.filter(c => c.openSubscriptionCount === 0).map(p => p.id)
                          })
                        }
                        else {
                          this.setState({
                            selectedIDs: []
                          })
                        }
                      }}
                    />
                  </th>
                  <th style={{ width: "30px", textAlign: "center" }}></th>
                  <th onClick={() => { this.sortObjectsBy("reseller") }}>{i18n.s("reseller")}</th>
                  <th onClick={() => { this.sortObjectsBy("company") }}>{i18n.s("company")}</th>
                  <th onClick={() => { this.sortObjectsBy("siret") }}>{i18n.s("SIRET")}</th>
                  <th onClick={() => { this.sortObjectsBy("postalCode") }}>{i18n.s("postalCode")}</th>
                  <th onClick={() => { this.sortObjectsBy("city") }}>{i18n.s("city")}</th>
                  <th onClick={() => { this.sortObjectsBy("country") }}>{i18n.s("country")}</th>
                  <th onClick={() => { this.sortObjectsBy("contact") }}>{i18n.s("contact")}</th>
                  <th onClick={() => { this.sortObjectsBy("email") }}>{i18n.s("email")}</th>
                  <th onClick={() => { this.sortObjectsBy("tel") }}>{i18n.s("tel")}</th>
                  <th onClick={() => { this.sortObjectsBy("openSubscriptionCount") }}>{i18n.s("openSubscriptionCount")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  customers.map((obj) => {
                    return this.renderObjectRow(obj)
                  })
                }
              </tbody>
            </table>
          </div>


          {
            editObject !== null || createNew ? <EditCustomerForm editCustomerID={editObject ? editObject.id : null} siret={null} onCancel={() => { this.setState({ editObject: null, createNew: false }) }} onSave={() => { this.reloadObjects() }} /> : null
          }

          {
            showDeleteDialog ? this.renderDeleteDialog() : null
          }

          {
            createReseller ? <EditResellerForm editResellerID={null} onCancel={() => { this.setState({ createReseller: false }) }} onSave={async (reseller: IReseller) => {
              const { editObject } = this.state;
              await this.reloadResellers();
              if (editObject) {
                this.setState({
                  editObject: {
                    ...editObject,
                    reseller: reseller.id,
                  }
                })
              }
            }} /> : null
          }

        </div>
      </Fragment>

    );
  }
}

export default CustomerManagement;