import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { IBareMetal } from '../../apitypes/index';

export default class BaremetalModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getBareMetals(): Promise<IBareMetal[] | null> {
    let response = await GetAPI(`${this.baseUri}/baremetals`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async createBareMetal(server: IBareMetal): Promise<IBareMetal | null> {
    let response = await PostAPI(`${this.baseUri}/baremetals`, this.token, server, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateBareMetal(id: number, server: IBareMetal): Promise<IBareMetal | null> {
    let response = await PutAPI(`${this.baseUri}/baremetals/${id}`, this.token, server, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteBareMetal(id: number): Promise<boolean> {
    let response = await DeleteAPI(`${this.baseUri}/baremetals/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

  async getBaremetalAvailableVMID(id: number): Promise<number> {
    let response = await GetAPI(`${this.baseUri}/baremetals/${id}/newvmid`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return 0;
    }
  }

  async getAllVMs(): Promise<any[]> {
    let response = await GetAPI(`${this.baseUri}/baremetals/all/vms`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async getBaremetalVMs(id: number): Promise<any[]> {
    let response = await GetAPI(`${this.baseUri}/baremetals/${id}/vms`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

  async getBaremetalVMConfig(bmID: number, vmid: number): Promise<any | null> {
    let response = await GetAPI(`${this.baseUri}/baremetals/${bmID}/vm/${vmid}/config`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateBaremetalVMConfig(bmID: number, vmid: number, config: any): Promise<any | null> {
    let response = await PutAPI(`${this.baseUri}/baremetals/${bmID}/vm/${vmid}/config`, this.token, config, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async cloneBaremetalTemplate(bmID: number, templateid: number, newvmid: number, newDiskSize: string | null, config: any): Promise<any | null> {
    let response = await PostAPI(`${this.baseUri}/baremetals/${bmID}/vm/${templateid}/clone/${newvmid}`, this.token, {
      config: config,
      newDiskSize: newDiskSize
    }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async stopBaremetalVM(bmID: number, vmid: number): Promise<any | null> {
    let response = await PostAPI(`${this.baseUri}/baremetals/${bmID}/vm/${vmid}/stop`, this.token, null, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async startBaremetalVM(bmID: number, vmid: number): Promise<any | null> {
    let response = await PostAPI(`${this.baseUri}/baremetals/${bmID}/vm/${vmid}/start`, this.token, null, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async restartBaremetalVM(bmID: number, vmid: number): Promise<any | null> {
    let response = await PostAPI(`${this.baseUri}/baremetals/${bmID}/vm/${vmid}/restart`, this.token, null, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteBaremetalVM(bmID: number, vmid: number): Promise<any | null> {
    let response = await DeleteAPI(`${this.baseUri}/baremetals/${bmID}/vm/${vmid}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }
}




