import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { IReseller } from '../../apitypes/index';


export default class ResellerModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getResellers(): Promise<IReseller[]> {
    let response = await GetAPI(`${this.baseUri}/resellers`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getReseller(id: number): Promise<IReseller | null> {
    let response = await GetAPI(`${this.baseUri}/resellers/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async createReseller(reseller: IReseller): Promise<IReseller | null> {
    let response = await PostAPI(`${this.baseUri}/resellers`, this.token, reseller, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateReseller(id: number, reseller: IReseller): Promise<IReseller | null> {
    let response = await PutAPI(`${this.baseUri}/resellers/${id}`, this.token, reseller, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteReseller(id: number): Promise<boolean> {
    let response = await DeleteAPI(`${this.baseUri}/resellers/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true
    }
    else {
      console.error(msg.error);
      return false;
    }
  }
}




