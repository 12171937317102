import API from './api/API';
import EventEmitter from 'events';
import { IUser } from './apitypes';
import storage from './storage';

export interface IPageProps {
}

export enum E_SORTORDER {
  ASC = "asc",
  DESC = "desc"
}

export interface IMessage {
  id: number,
  text: string,
  type: string,
  closing: boolean,
  delay: number
}

let messageID = 0;
const emitter = new EventEmitter();

const authUser: IUser = storage.getObject("user") as IUser;
const { hostname, host, protocol } = document.location;
let apiURI = `${protocol}//${host}/api`;
if (hostname === "localhost") {
  apiURI = `${protocol}//${hostname}:9545`;
}
console.log(`Using API URI ${apiURI}`);
export const api = new API(apiURI, authUser !== null && authUser.token ? authUser.token : "");



export const showLoading = (loading: boolean) => {
  emitter.emit('loading', loading);
}

export const showInfo = (text: string, delay = 4000) => {
  messageID++;
  emitter.emit('message', {
    id: messageID,
    text,
    type: 'info',
    closing: false,
    delay
  } as IMessage)
}

export const showError = (text: string, delay = 4000) => {
  messageID++;
  emitter.emit('message', {
    id: messageID,
    text,
    type: 'error',
    closing: false,
    delay
  } as IMessage)
}

export const showSuccess = (text: string, delay = 4000) => {
  messageID++;
  emitter.emit('message', {
    id: messageID,
    text,
    type: 'success',
    closing: false,
    delay
  } as IMessage)
}

/**
 * WARNING this method whill return a new array, the original array will not be mutated !
 * 
 * @param objects array of something
 * @param sortBy sort by this property
 * @param sortOrder sort with this order
 * @returns 
 */
export function sortObjects(objects: any[], sortBy: string, sortOrder: E_SORTORDER): any[] {
  const sorted = objects.sort((a: any, b: any): number => {
    if (sortOrder === E_SORTORDER.ASC) {
      if (!a || a[sortBy] === null || a[sortBy] === undefined) {
        return -1
      }
      return a[sortBy].localeCompare ? a[sortBy].localeCompare(b[sortBy]) : a[sortBy] - b[sortBy];
    }
    else {
      if (!b || b[sortBy] === null || b[sortBy] === undefined) {
        return -1
      }
      return b[sortBy].localeCompare ? b[sortBy].localeCompare(a[sortBy]) : b[sortBy] - a[sortBy];
    }
  });
  return [...sorted];
}

export default emitter;