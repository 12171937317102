import { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import Dialog from '../../comps/dialog/Dialog';
import { IUser, IReseller, hasAnyPrivileges, EPrivileges } from '../../apitypes/index';
import i18n from '../../i18n/I18n';
import EditUserForm from './EditUserForm';

import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';
import { RiLockPasswordLine } from "react-icons/ri";
import { TbEyeglass } from "react-icons/tb";


interface IState {
  users: IUser[],
  resellers: IReseller[],
  search: string,
  editUser: IUser | null,
  createNew: boolean
  selectedIDs: number[],
  showDeleteDialog: boolean,
  sortBy: string,
  sortOrder: E_SORTORDER
}

class UserManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      users: [],
      resellers: [],
      search: '',
      editUser: null,
      createNew: false,
      selectedIDs: [],
      showDeleteDialog: false,
      sortBy: '',
      sortOrder: E_SORTORDER.ASC
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  async reloadObjects() {

    showLoading(true);
    this.setState({
      editUser: null,
      createNew: false,
      selectedIDs: [],
      showDeleteDialog: false,
      users: await api.user.getUsers(),
      resellers: await api.reseller.getResellers()
    });
    showLoading(false);
  }

  sortObjectsBy(sortBy: string) {
    const { users, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      users: sortObjects(users, sortBy, newSortOrder)
    });
  }

  findReseller(id: number | null): IReseller | null {
    if (id === null) {
      return null;
    }
    const { resellers } = this.state;
    const theReseller = resellers.find(p => p.id === id);
    return theReseller !== undefined ? theReseller : null
  }


  async sendTemporaryToken(user: IUser) {
    showLoading(true);
    const sent = await api.user.generateTempToken(user);
    if (sent) {
      showSuccess(i18n.s('success'));
    }
    else {
      showError(i18n.s('error'));
    }
    showLoading(false);
  }

  renderDeleteDialog() {
    const { selectedIDs } = this.state;
    return <Dialog
      title={i18n.s("deleteUserConfirm")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          showDeleteDialog: false
        })
      }}
      onOK={async () => {
        showLoading(true);
        for (var id of selectedIDs) {
          const ok = await api.user.deleteUser(id);
          if (!ok) {
            showError(i18n.s("userDeleteError"));
          }
          else {
            showSuccess(i18n.s('success'));
          }
        }
        showLoading(false);
        this.reloadObjects();
      }}
    >
      <div>
        <label>{i18n.s("deleteUserConfirmString", [selectedIDs.length.toString()])}</label>
      </div>
    </Dialog>
  }

  renderObjectRow(user: IUser): React.ReactNode {
    const { selectedIDs } = this.state;
    const reseller = this.findReseller(user.reseller);

    const isSelected = selectedIDs.findIndex(suid => suid === user.id) > -1;
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (user.email.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (reseller !== undefined && reseller !== null && reseller.company.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        if (selectedIDs.indexOf(user.id) !== -1) {
          this.setState({
            selectedIDs: selectedIDs.filter(suid => suid !== user.id)
          });
        }
        return null;
      }
    }

    return <tr key={user.id} >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              this.setState({
                selectedIDs: [
                  ...selectedIDs,
                  user.id
                ]
              })
            }
            else {
              this.setState({
                selectedIDs: selectedIDs.filter(suid => suid !== user.id)
              })
            }
          }}
        />
      </td>
      <td><button onClick={() => { this.setState({ editUser: user, createNew: false }) }}><VscEdit /></button></td>
      <td>{reseller !== null ? reseller.company : ""}</td>
      <td >{user.email}</td>
      <td>{user.lastSeen ? api.fromUnixTime(user.lastSeen).toLocaleString() : i18n.s("never")}</td>
      {
        hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
          ? <td >
            <div className='button-actions'>
              <button className='warning' title={i18n.s("cgvReset")} onClick={async () => {
                await api.user.updateUser(user.id, {
                  ...user,
                  cgvSeen: null
                });
                this.reloadObjects();
              }}><TbEyeglass /></button>
              <label>{user.cgvSeen ? api.fromUnixTime(user.cgvSeen).toLocaleString() : i18n.s("never")}</label>
            </div>
          </td>
          : null
      }
      <td>
        <div className='button-actions'>
          <button className='info' title={i18n.s('sendTemporaryToken')} onClick={() => { this.sendTemporaryToken(user) }}><RiLockPasswordLine /></button>
        </div>
      </td>
    </tr>
  }

  render() {
    const { users, search, editUser, selectedIDs, showDeleteDialog, createNew } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("users")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <button onClick={() => { this.reloadObjects() }}><VscRefresh /></button>
            {
              hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_USERS])
                ? <Fragment><button className='success' onClick={() => {
                  this.setState({
                    editUser: null,
                    createNew: true
                  })
                }}><BsPlus /></button>
                  <button className='danger' disabled={selectedIDs.length === 0} onClick={() => {
                    this.setState({
                      showDeleteDialog: true
                    })
                  }}><AiOutlineDelete /></button>
                </Fragment>
                : null
            }
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "30px", textAlign: "center" }}>
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (e.currentTarget.checked) {
                          this.setState({
                            selectedIDs: users.map(u => u.id)
                          })
                        }
                        else {
                          this.setState({
                            selectedIDs: []
                          })
                        }
                      }}
                    />
                  </th>
                  <th style={{ width: "30px", textAlign: "center" }}></th>
                  <th onClick={() => { this.sortObjectsBy("reseller") }}>{i18n.s("reseller")}</th>
                  <th onClick={() => { this.sortObjectsBy("email") }}>{i18n.s("email")}</th>
                  <th onClick={() => { this.sortObjectsBy("lastSeen") }}>{i18n.s("lastseen")}</th>
                  {
                    hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
                      ? <th onClick={() => { this.sortObjectsBy("cgvSeen") }}>{i18n.s("cgvSeen")}</th>
                      : null
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  users.map((user) => {
                    return this.renderObjectRow(user)
                  })
                }
              </tbody>
            </table>
          </div>

          {
            editUser !== null || createNew
              ? <EditUserForm
                editUserID={editUser ? editUser.id : null}
                onCancel={() => {
                  this.setState({ editUser: null, createNew: false })
                }}
                onSave={() => {
                  showSuccess(i18n.s('success'));
                  this.reloadObjects();
                }}
              />
              : null
          }

          {
            showDeleteDialog ? this.renderDeleteDialog() : null
          }

        </div>
      </Fragment>

    );
  }
}

export default UserManagement;