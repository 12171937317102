import React, { Component } from 'react';
import storage from './storage';
import "./styles/support.scss";
import "./styles/main.scss";
import i18n from './i18n/I18n';
import fr_FR from './i18n/languages/fr_FR.json';
import Login from './pages/login/Login';
import Menu, { IMenuItem } from './comps/menu/menu';
import FileExplorer from './pages/fileExplorer/FileExplorer';
import logoIPConnect from './images/logo_ipconnect.png';
import logoIPC from './images/logo-ipc.png';
import queryString from 'query-string';
import shared, { api, showInfo, IMessage } from './sharedInterfaces';
import { IUser, EPrivileges, hasAnyPrivileges } from './apitypes/index';
import UserManagement from './pages/usersManagement/UserManagement';
import ResellerManagement from './pages/resellerManagement/ResellerManagement';
import CustomerManagement from './pages/customerManagement/CustomerManagement';
import CGV from './pages/cgv/CGV';
import DHCPManagement from './pages/dhcpManagement/DHCPManagement';
import ProductManagement from './pages/ProductManagement/ProductManagement';
import SubscriptionManagement from './pages/SubscriptionManagement/SubscriptionManagement';
import PricelistManagement from './pages/PricelistManagement/PricelistManagement';
import MyAccountManagement from './pages/myAccountManagement/MyAccountManagement';
import Eligibility from './pages/Eligibility/Eligibility';
import CentrexManagement from './pages/CentrexManagement/CentrexManagement';
import XorcomLicensesManagement from './pages/XorcomLicensesManagement/XorcomLicensesManagement';

import { VscFolderLibrary, VscDebugDisconnect } from 'react-icons/vsc';
import { BsBuildings } from 'react-icons/bs';
import { RiFilePaper2Line } from 'react-icons/ri';
import { HiOutlineUsers } from 'react-icons/hi';
import { ImUserTie } from 'react-icons/im';
import { LuNetwork, LuPackageOpen, LuEuro, LuServer } from 'react-icons/lu';
import { LiaFileContractSolid } from "react-icons/lia";
import { FaUserCog } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { GrLicense } from "react-icons/gr";
import TaskList from './comps/taskList/TaskList';



interface IProps { }
interface IState {
  authUser: IUser | null,
  lastLogin: string,
  menu: {
    selected: string,
    items: Array<IMenuItem>
  },
  loading: boolean,
  messages: IMessage[]
}

class App extends Component<IProps, IState> {
  infoErrorSuccessTimer: any;
  loadingStopTimer: any;

  constructor(props: IProps) {
    super(props);
    i18n.loadLanguageDefinition(fr_FR);

    var authUser: IUser = storage.getObject("user") as IUser;

    const lastLogin = storage.getString("lastLogin");
    const selectedMenuItem = storage.getString("selectedMenu");
    this.state = {
      authUser,
      lastLogin,
      menu: {
        selected: selectedMenuItem ? selectedMenuItem : 'fileExplorer',
        items: []
      },
      loading: false,
      messages: []
    }

    api.on('disconnected', () => {
      console.warn("Auth error - Disconnecting user");
      this.disconnectUser();
    });

    this.infoErrorSuccessTimer = null;
    this.loadingStopTimer = null;

    shared.on('loading', (setLoading) => {
      this.setState({
        loading: setLoading
      });
    });

    shared.on('message', (msg: IMessage) => {
      console.log(`${msg.type}: ${msg.text}`);
      this.setState({
        messages: [
          ...this.state.messages,
          msg
        ]
      }, () => {
        setTimeout(() => {
          this.setState({
            messages: this.state.messages.map((m) => {
              return m.id === msg.id ? { ...m, closing: true } : m;
            })
          })
        }, msg.delay - 500);
        setTimeout(() => {
          this.setState({
            messages: this.state.messages.filter(m => m.id !== msg.id)
          })
        }, msg.delay)
      });
    });
  }


  showMenu(): Promise<void> {
    const { authUser, menu } = this.state;
    return new Promise((resolve) => {
      this.setState({
        menu: {
          ...menu,
          items: [
            {
              index: "MyAccount",
              label: i18n.s("myAccount"),
              icon: <FaUserCog />,
              component: <MyAccountManagement />,
              show: true
            }, {
              index: "fileExplorer",
              label: i18n.s("fileExplorer"),
              icon: <VscFolderLibrary />,
              component: <FileExplorer />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.READ_FILES])
            }, {
              index: "Eligbility",
              label: i18n.s("eligibility"),
              icon: <FaMapLocationDot />,
              component: <Eligibility />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.ELIGIBILITY]),
            }, {
              index: "resellers",
              label: i18n.s("resellers"),
              icon: <ImUserTie />,
              component: <ResellerManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_RESELLERS])
            }, {
              index: "customers",
              label: i18n.s("customers"),
              icon: <BsBuildings />,
              component: <CustomerManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.READ_CUSTOMERS])
            }, {
              index: "subscriptions",
              label: i18n.s("subscriptions"),
              icon: <LiaFileContractSolid />,
              component: <SubscriptionManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.READ_SUBSCRIPTIONS])
            }, {
              index: "products",
              label: i18n.s("products"),
              icon: <LuPackageOpen />,
              component: <ProductManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_PRODUCTS])
            }, {
              index: "pricelists",
              label: i18n.s("priceLists"),
              icon: <LuEuro />,
              component: <PricelistManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_PRICELIST])
            }, {
              index: "CGV",
              label: i18n.s("CGV"),
              icon: <RiFilePaper2Line />,
              component: <CGV />,
              show: true
            }, {
              index: "DHCP",
              label: i18n.s("DHCPExplorer"),
              icon: <LuNetwork />,
              component: <DHCPManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN])
            }, {
              index: "Centrex",
              label: i18n.s("Centrex"),
              icon: <LuServer />,
              component: <CentrexManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.READ_BAREMETAL, EPrivileges.WRITE_BAREMETAL, EPrivileges.READ_VMS, EPrivileges.WRITE_VMS])
            }, {
              index: "XorcomLicenses",
              label: i18n.s("xorcomLicenses"),
              icon: <GrLicense />,
              component: <XorcomLicensesManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.READ_XORCOM_LICENSES, EPrivileges.WRITE_XORCOM_LICENSES])
            }, {
              index: "users",
              label: i18n.s("users"),
              icon: <HiOutlineUsers />,
              component: <UserManagement />,
              show: hasAnyPrivileges(authUser, [EPrivileges.SUPER_ADMIN, EPrivileges.READ_USERS])
            }, {
              index: "logout",
              label: i18n.s("logout"),
              icon: <VscDebugDisconnect />,
              component: null,
              show: true,
              backgroundColor: "#aa0524",
              color: "#ffffff"
            }
          ]
        }
      }, () => {
        resolve();
      })
    })
  }

  async componentDidMount() {
    const { authUser } = this.state;

    if (authUser !== null) {
      //check if user session still exists
      if (await api.auth.isConnected() === false) {
        console.error("User's session does not exists.");
        storage.deleteValue("user");
        this.setState({
          authUser: null
        });
      }
      else {
        await this.showMenu();
        if (window.location.search) {
          const { menu } = this.state;
          const parsedQuery = queryString.parse(window.location.search);
          if (parsedQuery.subscription) {
            this.setState({
              menu: {
                ...menu,
                selected: "subscriptions"
              }
            });
          }
        }
      }
    }
  }


  //Callback called when the user logged in
  onAuth(authUser: IUser, persist: boolean): void {
    const { menu } = this.state;
    storage.setValue("user", authUser);
    storage.setValue("lastLogin", authUser.email);
    if (persist) {
      storage.persist();
    }
    this.setState({
      authUser,
      lastLogin: authUser.email,
      menu: {
        ...menu,
        selected: authUser.cgvSeen === null ? "CGV" : menu.selected
      }
    }, () => {
      this.showMenu();
    });
  }


  async disconnectUser() {
    const { menu } = this.state;
    this.setState({
      authUser: null,
      menu: {
        ...menu,
        selected: "fileExplorer"
      }
    });
    storage.deleteValue("user");
    storage.deleteValue("selectedMenu");
  }

  onMenuItemChange(itemIndex: string): void {
    const { menu } = this.state;
    if (itemIndex === "logout") {
      api.auth.logout();
      this.disconnectUser();
    }
    else {
      this.setState({
        menu: {
          ...menu,
          selected: itemIndex
        }
      });
      storage.setValue("selectedMenu", itemIndex);
    }
  }

  render() {
    const { authUser, lastLogin, menu, messages, loading } = this.state;

    if (authUser === null) {
      return (
        <div className="App">
          <Login
            lastLogin={lastLogin}
            persist={storage.isPersistent()}
            onAuth={(authUser: IUser, persist: boolean) => { this.onAuth(authUser, persist); }}
            logo=<img src={logoIPConnect} alt='IPConnect' />
          />
        </div>
      );
    }

    const selectedMenuItem = menu.items.find(i => i.index === menu.selected);

    return (
      <div className="App">
        <div id="top-bar">
          <img src={logoIPC} alt='IPC' className='logo-ipc' onClick={() => { showInfo('IPConnect RuLeZ !') }} />
          <label>
            {
              i18n.s("welcomeUser", [authUser.email])
            }
          </label>
        </div>
        <div id="main">
          <div id="menu-left">
            <Menu
              items={menu.items}
              selected={menu.selected}
              onChange={(selectedItem: IMenuItem) => { this.onMenuItemChange(selectedItem.index) }} />
          </div>
          <div id="main-panel">
            {
              selectedMenuItem ? selectedMenuItem.component : null
            }
            <TaskList />
          </div>
        </div>

        {
          messages.length > 0
            ? <div className='messages'>
              {
                messages.map((msg) => {
                  return <div className={`message ${msg.closing ? 'closing' : ''}`}>
                    <div className={`${msg.type}`}>{msg.text}</div>
                  </div>
                })
              }
            </div>
            : null
        }

        {
          loading ? <div className='loading'>
            <label>{i18n.s("loading")}</label>
          </div> : null
        }
      </div>
    );
  }

}

export default App;