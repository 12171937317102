import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/dhcpManagement.scss';
import { IPageProps, } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';

import { MdPassword } from "react-icons/md";


interface IState {
  password1: string,
  password2: string
}

class MyAccountManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      password1: '',
      password2: ''
    }
  }

  async componentDidMount(): Promise<void> {

  }


  isValidPassword(password: string) {
    const reg = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{8,}/);
    return reg.test(password);
  }


  async changePassword() {
    const { password1 } = this.state;

    if (!api.currentUser) {
      return;
    }
    const user = await api.user.getUser(api.currentUser?.id);
    if (!user) {
      return;
    }

    showLoading(true);
    const res = await api.user.updateUser(api.currentUser?.id, {
      ...user,
      password: password1
    });
    showLoading(false);

    if (res === null) {
      showError(i18n.s("passwordNotModified"));
    }
    else {
      showSuccess(i18n.s("passwordModified"));
    }
  }

  render() {
    const { password1, password2 } = this.state;

    return (
      <Fragment>
        <div className='management '>
          <h1>{i18n.s("myAccount")}</h1>
          <div className='form'>
            <h2>{i18n.s('userPasswordModification')}</h2>
            <div className='row input-with-label'>
              <div><MdPassword /> <label>{i18n.s('userPassword1')}</label></div>
              <input
                autoComplete='off'
                type="password"
                value={password1}
                style={this.isValidPassword(password1) === false ? { border: "1px solid red" } : undefined}
                placeholder={i18n.s("typePassword1")}
                onChange={(e) => {
                  this.setState({
                    password1: e.currentTarget.value.trim()
                  })
                }} />
            </div>
            {
              password1 === "" || this.isValidPassword(password1)
                ? null
                : <div className='error-box'>{i18n.s("passwordIsInvalid")}</div>
            }
            <div className='row input-with-label'>
              <div><MdPassword /> <label>{i18n.s('userPassword2')}</label></div>
              <input
                autoComplete='off'
                type="password"
                value={password2}
                placeholder={i18n.s("typePassword2")}
                style={password1 !== password2 ? { border: "1px solid red" } : undefined}
                onChange={(e) => {
                  this.setState({
                    password2: e.currentTarget.value.trim()
                  })
                }} />
            </div>
            {
              password2 !== "" && password1 !== password2
                ? <div className='error-box'>{i18n.s("passwordsDoesNotMatch")}</div>
                : null
            }
            <div className='buttons'>
              <button disabled={this.isValidPassword(password1) === false || password1 !== password2} className='success' onClick={() => { this.changePassword() }}>
                <label>{i18n.s("updatePassword")}</label>
              </button>
            </div>
          </div>

        </div>
      </Fragment>

    );
  }
}

export default MyAccountManagement;