import React, { Component } from 'react';
import i18n from '../../i18n/I18n';
import '../../styles/importCSV.scss';

interface IState {
  rawContent: string,
  error: string | null,
  separator: string,
  eol: string,
}

interface IProps {
  columns: string[],
  onImportGenerated: Function,
  separator: string,
}

class importCSV extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      rawContent: "",
      error: null,
      separator: this.props.separator,
      eol: "\n"
    };
  }


  async loadFile(file: File) {
    const content = await file.text();
    const eol: string = this.findFileEOL(content);
    this.setState({
      rawContent: content,
      error: null,
      eol: eol
    }, () => {
      this.generateImportObject();
    });
  }


  findFileEOL(content: string) {
    let eol = "\n";
    if (content.indexOf('\r\n') > -1) {
      eol = "\r\n";
    }
    return eol;
  }



  generateImportObject() {
    const { columns, onImportGenerated } = this.props;
    const { eol, rawContent, separator } = this.state;

    const contentLines = rawContent.split(new RegExp(eol, "gi"));

    if (!contentLines) {
      return null;
    }


    const result = []
    for (let line of contentLines) {
      const tab = line.split(new RegExp(separator, "gi"));
      if (tab.length !== columns.length) {
        continue;
      }

      if (tab.length === columns.length) {
        result.push(tab);
      }
    }
    onImportGenerated(result);
  }


  render() {
    const { eol, rawContent, error, separator } = this.state;
    const { columns } = this.props
    const contentLines = rawContent.split(new RegExp(eol, "gi"));
    return (
      <div className='import-csv'>
        <div >
          <label>{i18n.s('selectFileToImport')}</label>
          <input type='file' accept='.csv, .txt' onChange={(e) => {
            if (e.currentTarget.files && e.currentTarget.files.length > 0) {
              this.loadFile(e.currentTarget.files[0])
            }
          }} />
        </div>
        {
          error ? <div className='error'>{error}</div> : null
        }
        <div>
          <label>{i18n.s("separator")}</label>
          <select value={separator} onChange={(e) => { this.setState({ separator: e.currentTarget.value }, () => { this.generateImportObject() }) }}>
            <option value="\t">tab</option>
            <option value=",">,</option>
            <option value=";">;</option>
            <option value="|">|</option>
          </select>
        </div>
        <h4>{i18n.s('preview')}</h4>
        <div >
          <table>
            <thead>
              <tr>
                {
                  columns.map((col: string) => {
                    return <th>{col}</th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                contentLines
                  ? contentLines.map((cl) => {
                    const cells = cl.split(new RegExp(separator, "gi"));
                    return <tr>
                      {
                        cells.map((cel) => {
                          return <td>{cel}</td>
                        })
                      }
                    </tr>
                  })
                  : null
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default importCSV;