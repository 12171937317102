import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { ICustomer } from '../../apitypes/index';

export default class CustomerModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getCustomers(): Promise<ICustomer[]> {
    let response = await GetAPI(`${this.baseUri}/customers`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getCustomer(id: number): Promise<ICustomer | null> {
    let response = await GetAPI(`${this.baseUri}/customers/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async createCustomer(customer: ICustomer): Promise<ICustomer | null> {
    let response = await PostAPI(`${this.baseUri}/customers`, this.token, customer, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updateCustomer(id: number, customer: ICustomer): Promise<ICustomer | null> {
    let response = await PutAPI(`${this.baseUri}/customers/${id}`, this.token, customer, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async deleteCustomer(id: number): Promise<boolean> {
    let response = await DeleteAPI(`${this.baseUri}/customers/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true
    }
    else {
      console.error(msg.error);
      return false;
    }
  }
}




