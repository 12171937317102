import { GetAPI, DeleteAPI } from './http';
import { IDHCPEntry } from '../../apitypes/index';

export default class DHCPModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getDHCPEntries(): Promise<IDHCPEntry[]> {
    let response = await GetAPI(`${this.baseUri}/dhcp`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }

}




