
import { IUser } from '../apitypes/index';
import EventEmitter from 'events';
import AuthModule from "./apiModules/auth";
import UserModule from "./apiModules/user";
import FileModule from "./apiModules/file";
import CustomerModule from "./apiModules/customer";
import ResellerModule from "./apiModules/reseller";
import DHCPModule from "./apiModules/dhcp";
import ProductModule from "./apiModules/product";
import SubscriptionModule from "./apiModules/subscription";
import PricelistModule from "./apiModules/pricelist";
import CGVModule from './apiModules/cgv';
import SubscriptionStepModule from './apiModules/subscriptionStep';
import eligibilityModule from './apiModules/eligibilite';
import XorcomLicensesModule from './apiModules/xorcomLicenses';
import BaremetalModule from './apiModules/baremetal';
import TasksModule from './apiModules/tasks';

export default class API extends EventEmitter {
  public currentUser: IUser | null;


  public auth: AuthModule;
  public file: FileModule;
  public user: UserModule;
  public customer: CustomerModule;
  public reseller: ResellerModule;
  public dhcp: DHCPModule;
  public product: ProductModule;
  public subscription: SubscriptionModule;
  public pricelist: PricelistModule;
  public cgv: CGVModule;
  public baremetal: BaremetalModule;
  public subscriptionStep: SubscriptionStepModule;
  public eligbility: eligibilityModule;
  public xorcomLicenses: XorcomLicensesModule;
  public tasks: TasksModule

  constructor(baseUri: string, token: string) {
    super();
    this.currentUser = null;

    const onAuthErrorCallback = () => {
      this.emit('disconnected');
    }

    this.file = new FileModule(baseUri, token, onAuthErrorCallback);
    this.user = new UserModule(baseUri, token, onAuthErrorCallback);
    this.customer = new CustomerModule(baseUri, token, onAuthErrorCallback);
    this.reseller = new ResellerModule(baseUri, token, onAuthErrorCallback);
    this.dhcp = new DHCPModule(baseUri, token, onAuthErrorCallback);
    this.product = new ProductModule(baseUri, token, onAuthErrorCallback);
    this.subscription = new SubscriptionModule(baseUri, token, onAuthErrorCallback);
    this.pricelist = new PricelistModule(baseUri, token, onAuthErrorCallback);
    this.cgv = new CGVModule(baseUri, token, onAuthErrorCallback);
    this.baremetal = new BaremetalModule(baseUri, token, onAuthErrorCallback);
    this.subscriptionStep = new SubscriptionStepModule(baseUri, token, onAuthErrorCallback);
    this.eligbility = new eligibilityModule(baseUri, token, onAuthErrorCallback);
    this.xorcomLicenses = new XorcomLicensesModule(baseUri, token, onAuthErrorCallback);
    this.tasks = new TasksModule(baseUri, token, onAuthErrorCallback);
    this.auth = new AuthModule(baseUri, token, onAuthErrorCallback, (newToken: string, currentUser: IUser) => {
      this.currentUser = currentUser;
      this.file.setToken(newToken);
      this.user.setToken(newToken);
      this.customer.setToken(newToken);
      this.reseller.setToken(newToken);
      this.dhcp.setToken(newToken);
      this.product.setToken(newToken);
      this.subscription.setToken(newToken);
      this.pricelist.setToken(newToken);
      this.cgv.setToken(newToken);
      this.baremetal.setToken(newToken);
      this.subscriptionStep.setToken(newToken);
      this.eligbility.setToken(newToken);
      this.xorcomLicenses.setToken(newToken);
      this.tasks.setToken(newToken);
    });
  }

  unixTime(): number {
    return Math.round(Date.now() / 1000);
  }

  fromUnixTime(epoch: number): Date {
    let d = new Date(0);
    d.setUTCSeconds(epoch);
    return d;
  }

  toUnixTime(date: Date): number {
    return date.getUTCSeconds();
  }
}



