import React, { Component } from 'react';
import Dialog from '../../comps/dialog/Dialog';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { ICustomer, IReseller, IXorcomLicense, hasAnyPrivileges, EPrivileges } from '../../apitypes';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import Select from 'react-select';
import EditCustomerForm from '../customerManagement/EditCustomerForm';
import EditResellerForm from '../resellerManagement/EditResellerForm';

import { RxPencil1 } from 'react-icons/rx';
import { BsPlus } from 'react-icons/bs';



export interface IEditProps {
  editLicenseKey: string | null,
  onCancel: Function,
  onSave: Function
}

export interface IEditState {
  license: IXorcomLicense | null,
  isNewLicense: boolean,
  resellers: IReseller[],
  customers: ICustomer[],
  newCustomer: ICustomer | null,
  newReseller: IReseller | null
}


const LicenseSpecifications: Map<string, any> = new Map([
  ['cpbx-5-extensions', { readOnly: true, attributes: { extensions: 5 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-10-extensions', { readOnly: true, attributes: { extensions: 10 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-20-extensions', { readOnly: true, attributes: { extensions: 20 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-30-extensions', { readOnly: true, attributes: { extensions: 30 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-40-extensions', { readOnly: true, attributes: { extensions: 40 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-50-extensions', { readOnly: true, attributes: { extensions: 50 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-75-extensions', { readOnly: true, attributes: { extensions: 75 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-100-extensions', { readOnly: true, attributes: { extensions: 100 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-125-extensions', { readOnly: true, attributes: { extensions: 125 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-150-extensions', { readOnly: true, attributes: { extensions: 150 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-200-extensions', { readOnly: true, attributes: { extensions: 200 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-250-extensions', { readOnly: true, attributes: { extensions: 250 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-300-extensions', { readOnly: true, attributes: { extensions: 300 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-250-extensions', { readOnly: true, attributes: { extensions: 250 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-400-extensions', { readOnly: true, attributes: { extensions: 400 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-450-extensions', { readOnly: true, attributes: { extensions: 450 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-500-extensions', { readOnly: true, attributes: { extensions: 500 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-600-extensions', { readOnly: true, attributes: { extensions: 600 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-700-extensions', { readOnly: true, attributes: { extensions: 700 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-800-extensions', { readOnly: true, attributes: { extensions: 800 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-900-extensions', { readOnly: true, attributes: { extensions: 900 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-1000-extensions', { readOnly: true, attributes: { extensions: 1000 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-1250-extensions', { readOnly: true, attributes: { extensions: 1250 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-1500-extensions', { readOnly: true, attributes: { extensions: 1500 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-1750-extensions', { readOnly: true, attributes: { extensions: 1750 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-2000-extensions', { readOnly: true, attributes: { extensions: 2000 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-2500-extensions', { readOnly: true, attributes: { extensions: 2500 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-3000-extensions', { readOnly: true, attributes: { extensions: 3000 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-3500-extensions', { readOnly: true, attributes: { extensions: 3500 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-4000-extensions', { readOnly: true, attributes: { extensions: 4000 }, needValidFor: false, defaultValidFor: 0 }],
  ['flex-extensions', { readOnly: false, attributes: { extensions: 1 }, needValidFor: false, defaultValidFor: 36500 }],
  ['cpbx-cloudphone', { readOnly: false, attributes: { cloudphone: 1 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-cloudphone-annual', { readOnly: false, attributes: { cloudphone: 1 }, needValidFor: true, defaultValidFor: 365 }],
  ['cpbx-desktop', { readOnly: false, attributes: { desktop: 1 }, needValidFor: false, defaultValidFor: 0 }],
  ['cpbx-desktop-annual', { readOnly: false, attributes: { desktop: 1 }, needValidFor: true, defaultValidFor: 365 }]
]);


class EditXorcomLicenseForm extends Component<IEditProps, IEditState> {

  constructor(props: IEditProps) {
    super(props);
    this.state = {
      license: null,
      isNewLicense: true,
      resellers: [],
      customers: [],
      newCustomer: null,
      newReseller: null
    }
  }


  async componentDidMount(): Promise<void> {
    const { editLicenseKey } = this.props;
    this.setState({
      license: editLicenseKey !== null ? await api.xorcomLicenses.getLicense(editLicenseKey) : {
        key: "",
        reseller: null,
        customer: null,
        product: "",
        system: "",
        type: "",
        created: 0,
        active: 0,
        comment: "",
        attributes: {},
        ownedBy: "",
        registered: 0,
        firstRegistration: 0,
        validAfter: 0,
        validFor: 0,
        validUntil: 0,
        lastMail: 0
      },
      isNewLicense: editLicenseKey === null
    })
    this.reloadData();
  }

  async reloadData() {

    //load dependencies and products
    showLoading(true);
    this.setState({
      resellers: await api.reseller.getResellers(),
      customers: await api.customer.getCustomers(),
      newCustomer: null,
      newReseller: null
    });
    showLoading(false);
  }


  async saveObject() {
    const { onSave, editLicenseKey } = this.props;
    const { license, isNewLicense } = this.state;

    if (license === null) {
      return;
    }

    if (isNewLicense === false) { //UPdate license
      showLoading(true);
      let ownerChanged = false;
      if (license.system !== "") {
        //change owner
        ownerChanged = await api.xorcomLicenses.setLicenseSystemOwner(license.system, license.reseller, license.customer)
      }
      else {
        ownerChanged = await api.xorcomLicenses.setLicenseKeyOwner(license.key, license.reseller, license.customer)
      }
      if (ownerChanged) {
        showSuccess(i18n.s("xorcomLicenseOwnerChangeSuccess"))
        onSave(null);
      }
      else {
        showError(i18n.s("xorcomLicenseOwnerChangeFailed"))
      }

      showLoading(false);
    }
    else { //Create license
      if (license.product === "" || license.attributes === "") {
        showError(i18n.s("invalidXorcomLicense"));
      }
      const comment = `${license.comment}\nCreator: ${api.currentUser?.email}`;
      const createdKey = await api.xorcomLicenses.createLicenseKey(license.reseller, license.customer, license.product, "virtual", license.attributes, comment, license.validFor);
      if (!createdKey) {
        showError(i18n.s("xorcomLicenseCreationFailed"));
      }
      else {
        showSuccess(i18n.s("xorcomLicenseCreationSuccess"));
        onSave(createdKey);
      }
    }
  }


  render() {
    const { license, resellers, customers, newCustomer, newReseller, isNewLicense } = this.state;
    const { onCancel } = this.props;

    if (license === null) {
      return null;
    }


    const reseller = license.reseller ? resellers.find(r => r.id === license.reseller) || null : null;
    const customer = license.customer ? customers.find(c => c.id === license.customer) || null : null;


    let displayCustomers: ICustomer[] = [];
    if (reseller) {
      displayCustomers = customers.filter(c => c.reseller === reseller.id);
    }

    const selectedProductLicenseSpecifications = LicenseSpecifications.get(license.product) || null;

    return <Dialog
      title={i18n.s("editXorcomLicense")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      height='600px'
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        this.saveObject();
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('licenseKey')}</label></div>
            <input autoComplete='off' readOnly={true} type="text" value={license.key} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('licenseReseller')}</label></div>
            <div className='input-with-button'>
              <Select
                className='react-select'
                classNamePrefix='react-select'
                value={reseller ? { value: reseller, label: reseller.company } : null}
                onChange={(e) => {
                  this.setState({
                    license: {
                      ...license,
                      reseller: e?.value ? e.value.id : null
                    }
                  })
                }}
                options={
                  resellers.map((r) => {
                    return { value: r, label: r.company }
                  })
                }
                menuPlacement='auto'
              />
              <button className='success' disabled={hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_RESELLERS]) === false} onClick={() => {
                this.setState({
                  newReseller: {
                    id: -1,
                    company: '',
                    siret: '',
                    address1: '',
                    address2: '',
                    postalCode: '',
                    city: '',
                    country: '',
                    contact: '',
                    email: '',
                    tel: '',
                    tvaintracom: '',
                    naf: '',
                    pricelists: []
                  } as IReseller
                })
              }}><BsPlus /></button>
            </div>

          </div>
          {
            reseller
              ? <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('licenseCustomer')}</label></div>
                <div className='input-with-button'>

                  <Select
                    className='react-select'
                    classNamePrefix='react-select'
                    value={customer ? { value: customer, label: customer.company } : null}
                    onChange={(e) => {
                      this.setState({
                        license: {
                          ...license,
                          customer: e?.value ? e.value.id : null
                        }
                      })
                    }}
                    options={
                      displayCustomers.map((c) => {
                        return { value: c, label: c.company }
                      })
                    }
                    menuPlacement='auto'
                  />
                  <button className='success' disabled={hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_CUSTOMERS]) === false} onClick={() => {
                    this.setState({
                      newCustomer: {
                        id: -1,
                        reseller: api.currentUser && api.currentUser.reseller ? api.currentUser.reseller : -1,
                        company: '',
                        address1: '',
                        address2: '',
                        postalCode: '',
                        city: '',
                        country: 'France',
                        siret: '',
                        contact: '',
                        email: '',
                        tel: '',
                        tvaintracom: '',
                        naf: ''
                      } as ICustomer
                    })
                  }}><BsPlus /></button>
                </div>
              </div>
              : null
          }

          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('licenseProduct')}</label></div>
            <select value={license.product} disabled={isNewLicense === false} onChange={(e) => {
              const specifications = LicenseSpecifications.has(e.currentTarget.value) ? LicenseSpecifications.get(e.currentTarget.value) : null;
              const defaultAttributes = specifications ? specifications.attributes : {};
              const validFor = specifications && specifications.defaultValidFor ? specifications.defaultValidFor : 0;
              this.setState({
                license: {
                  ...license,
                  product: e.currentTarget.value,
                  attributes: defaultAttributes,
                  validFor: validFor
                }
              })
            }} >
              <option value=''>{i18n.s('licenseChooseProduct')}</option>
              <option value='flex-extensions'>CPBX Flexible</option>
              <option value='cpbx-5-extensions'>CPBX 5 Extensions</option>
              <option value='cpbx-10-extensions'>CPBX 10 Extensions</option>
              <option value='cpbx-20-extensions'>CPBX 20 Extensions</option>
              <option value='cpbx-30-extensions'>CPBX 30 Extensions</option>
              <option value='cpbx-40-extensions'>CPBX 40 Extensions</option>
              <option value='cpbx-50-extensions'>CPBX 50 Extensions</option>
              <option value='cpbx-75-extensions'>CPBX 75 Extensions</option>
              <option value='cpbx-100-extensions'>CPBX 100 Extensions</option>
              <option value='cpbx-125-extensions'>CPBX 125 Extensions</option>
              <option value='cpbx-150-extensions'>CPBX 150 Extensions</option>
              <option value='cpbx-200-extensions'>CPBX 200 Extensions</option>
              <option value='cpbx-250-extensions'>CPBX 250 Extensions</option>
              <option value='cpbx-300-extensions'>CPBX 300 Extensions</option>
              <option value='cpbx-250-extensions'>CPBX 250 Extensions</option>
              <option value='cpbx-400-extensions'>CPBX 400 Extensions</option>
              <option value='cpbx-450-extensions'>CPBX 450 Extensions</option>
              <option value='cpbx-500-extensions'>CPBX 500 Extensions</option>
              <option value='cpbx-600-extensions'>CPBX 600 Extensions</option>
              <option value='cpbx-700-extensions'>CPBX 700 Extensions</option>
              <option value='cpbx-800-extensions'>CPBX 800 Extensions</option>
              <option value='cpbx-900-extensions'>CPBX 900 Extensions</option>
              <option value='cpbx-1000-extensions'>CPBX 1000 Extensions</option>
              <option value='cpbx-1250-extensions'>CPBX 1250 Extensions</option>
              <option value='cpbx-1500-extensions'>CPBX 1500 Extensions</option>
              <option value='cpbx-1750-extensions'>CPBX 1750 Extensions</option>
              <option value='cpbx-2000-extensions'>CPBX 2000 Extensions</option>
              <option value='cpbx-2500-extensions'>CPBX 2500 Extensions</option>
              <option value='cpbx-3000-extensions'>CPBX 3000 Extensions</option>
              <option value='cpbx-3500-extensions'>CPBX 3500 Extensions</option>
              <option value='cpbx-4000-extensions'>CPBX 4000 Extensions</option>
              <option value='cpbx-cloudphone'>Coudphone Smartphone Flexible</option>
              <option value='cpbx-cloudphone-annual'>Coudphone Smartphone Annuel</option>
              <option value='cpbx-desktop'>Coudphone PC Flexible</option>
              <option value='cpbx-desktop-annual'>Coudphone PC Annuel</option>
            </select>
          </div>

          {
            selectedProductLicenseSpecifications
              ? <>
                {
                  Object.keys(license.attributes).map((property) => {
                    return <div className='row input-with-label'>
                      <div><RxPencil1 /> <label>{property}</label></div>
                      <input type="text" disabled={isNewLicense === false || selectedProductLicenseSpecifications?.readOnly} value={license.attributes[property]} onChange={(e) => {
                        this.setState({
                          license: {
                            ...license,
                            attributes: {
                              ...license.attributes,
                              [property]: e.currentTarget.value
                            }
                          }
                        })
                      }} />
                    </div>
                  })
                }

                {
                  selectedProductLicenseSpecifications && selectedProductLicenseSpecifications.needValidFor
                    ? <div className='row input-with-label'>
                      <div><RxPencil1 /> <label>{i18n.s('licenseValidFor')}</label></div>
                      <input type="number" min={365} step={365} disabled={isNewLicense === false} value={license.validFor} onChange={(e) => {
                        this.setState({
                          license: {
                            ...license,
                            validFor: Number.parseInt(e.currentTarget.value)
                          }
                        })
                      }} />
                    </div>
                    : null
                }


                <div className='row input-with-label'>
                  <div><RxPencil1 /> <label>{i18n.s('licenseSystem')}</label></div>
                  <input type="text" disabled={isNewLicense === false} value={license.system} onChange={(e) => {
                    this.setState({
                      license: {
                        ...license,
                        system: e.currentTarget.value
                      }
                    })
                  }} />
                </div>

                <div className='row input-with-label'>
                  <div><RxPencil1 /> <label>{i18n.s('licenseComment')}</label></div>
                  <textarea disabled={isNewLicense === false} value={license.comment} onChange={(e) => {
                    this.setState({
                      license: {
                        ...license,
                        comment: e.currentTarget.value
                      }
                    })
                  }} ></textarea>
                </div>

                <div className='row input-with-label'>
                  <div><RxPencil1 /> <label>{i18n.s('LicenseAttribtues')}</label></div>
                  <textarea disabled={true} value={JSON.stringify(license.attributes, null, '  ')}></textarea>
                </div>
              </>
              : null
          }
        </div>

        {
          newReseller ? <EditResellerForm
            editResellerID={null}
            onCancel={() => { this.setState({ newReseller: null }) }}
            onSave={async (reseller: IReseller) => {
              await this.reloadData();
              this.setState({
                license: {
                  ...license,
                  reseller: reseller.id
                }
              })
            }} />
            : null
        }

        {
          newCustomer ? <EditCustomerForm
            editCustomerID={null}
            siret={null}
            onCancel={() => { this.setState({ newCustomer: null }) }}
            onSave={async (customer: ICustomer) => {
              await this.reloadData();
              this.setState({
                license: {
                  ...license,
                  customer: customer.id
                }
              })
            }} />
            : null
        }

      </div>
    </Dialog>
  }
}

export default EditXorcomLicenseForm;