import { GetAPI, PostAPI } from './http';
import { IUser } from '../../apitypes/index';

export default class AuthModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;
  public onTokenChanged: Function;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null, onTokenChanged: Function) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
    this.onTokenChanged = onTokenChanged;
  }


  async login(username: string, password: string): Promise<IUser | null> {
    let response = await PostAPI(`${this.baseUri}/users/login`, this.token, {
      username,
      password
    }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      const currentUser = msg.data as IUser;
      this.token = msg.data.token;
      this.onTokenChanged(this.token, currentUser);
      return currentUser;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async logout(): Promise<boolean> {
    let response = await GetAPI(`${this.baseUri}/users/logout`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      this.token = "";
      this.onTokenChanged(this.token, null);
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

  async lostPassword(email: string): Promise<boolean> {
    let response = await GetAPI(`${this.baseUri}/users/lostpassword/${email}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

  async isConnected(): Promise<boolean> {
    try {
      let response = await GetAPI(`${this.baseUri}/users/connected`, this.token);
      const msg = await response.json();
      if (msg.status === "success") {
        const currentUser = msg.data as IUser;
        this.token = msg.data.token;
        this.onTokenChanged(this.token, currentUser);
        return true;
      }
      return false;
    }
    catch {
      this.token = "";
      this.onTokenChanged(this.token, null);
      return false;
    }
  }
}




