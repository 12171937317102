import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { IProduct, IPricelist, IPrice } from '../../apitypes/index';
import Dialog from '../../comps/dialog/Dialog';

import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { RxPencil1, RxUpload, RxDownload } from 'react-icons/rx';
import { Buffer } from 'buffer';
import ImportCSV from '../../comps/importCSV/ImportCSV';

interface IState {
  search: string,
  pricelists: IPricelist[],
  products: IProduct[],
  editPricelist: IPricelist | null,
  showDeleteDialog: boolean,
  prices: IPrice[],
  selectedIDs: number[],
  selectedProducts: string[],
  sortBy: string,
  sortOrder: E_SORTORDER,
  massPU: string,
  massFAS: string,
  massEngagement: string,
  massCOM: string,
  showImport: boolean,
  importLines: null | string[][]
}

class PricelistManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      pricelists: [],
      products: [],
      editPricelist: null,
      showDeleteDialog: false,
      prices: [],
      selectedIDs: [],
      selectedProducts: [],
      sortBy: '',
      sortOrder: E_SORTORDER.ASC,
      massPU: '',
      massFAS: '',
      massEngagement: '',
      massCOM: '',
      showImport: false,
      importLines: null
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  sortObjectsBy(sortBy: string) {
    const { pricelists, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      pricelists: sortObjects(pricelists, sortBy, newSortOrder)
    });
  }


  async reloadObjects() {

    showLoading(true);
    this.setState({
      pricelists: await api.pricelist.getPricelists(),
      products: await api.product.getProducts(),
      prices: [],
      editPricelist: null,
      selectedIDs: [],
      showDeleteDialog: false,
    });
    showLoading(false);
  }

  async createNewObject() {
    var editPricelist: IPricelist = {
      id: -1,
      name: ''
    }
    this.setState({
      editPricelist,
      prices: await this.loadAndMergePricesForCurrentPricelist(null)
    })
  }


  async loadAndMergePricesForCurrentPricelist(pricelist: IPricelist | null = null): Promise<IPrice[]> {

    const { products } = this.state;

    showLoading(true);
    const existingPrices = pricelist !== null ? await api.pricelist.getPrices(pricelist.id) : [];
    const prices: Array<IPrice> = [];
    for (var pro of products) {
      let pri: IPrice | undefined = existingPrices.find(p => p.product === pro.ref);
      if (!pri) {
        pri = {
          product: pro.ref,
          pu: 0,
          fas: 0,
          engagement: 0,
          com: 0
        } as IPrice;
      }
      prices.push(pri);
    }

    showLoading(false);
    return prices;

  }


  onPriceEdit(price: IPrice, propertyName: string, value: number) {
    const { prices } = this.state;
    this.setState({
      prices: prices.map((p) => {
        if (p.product === price.product) {
          return {
            ...price,
            [propertyName]: value
          }
        };
        return p
      })
    });
  }



  async savePricelist() {
    const { editPricelist, prices } = this.state;

    if (editPricelist === null) {
      return true;
    }

    showLoading(true);

    if (editPricelist.id === -1) { //new pricelist
      if (editPricelist.name.trim() === "") {
        showError(i18n.s("noPricelistName"));
        return false;
      }

      if (await api.pricelist.createPricelist(editPricelist, prices) === null) {
        showError(i18n.s("pricelistCreateError"));
        return false;
      }
      else {
        showSuccess(i18n.s('success'));
      }
    }
    else { //Existing pricelist
      if (await api.pricelist.updatePricelist(editPricelist.id, editPricelist, prices) === null) {
        showError(i18n.s("pricelistUpdateError"));
        return false;
      }
      else {
        showSuccess(i18n.s('success'));
      }
    }
    this.reloadObjects();

    showLoading(false);
    return true;
  }




  exportObjects() {
    const { prices, editPricelist } = this.state;
    let csv = "product\tpu\tfas\tengagement\tcom\r\n";
    for (var pri of prices) {
      csv += `${pri.product}\t${pri.pu}\t${pri.fas}\t${pri.engagement}\t${pri.com}\r\n`;
    }
    this.downloadDataToUser(csv, `export ${editPricelist?.name}.csv`);
  }

  async importObjects() {
    const { importLines } = this.state;

    if (importLines === null) {
      showError(i18n.s('importIsNull'));
      return;
    }

    //check column position
    const productIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "product");
    const puIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "pu");
    const fasIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "fas");
    const engagementIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "engagement");
    const comIndex = importLines[0].findIndex(c => c.trim().toLowerCase() === "com");
    if (productIndex === -1
      || puIndex === -1
      || fasIndex === -1
      || engagementIndex === -1
      || comIndex === -1
    ) {
      showError(i18n.s('missingColumn'));
      return null;
    }

    const modifiedPrices = [];
    for (let i = 1; i < importLines.length; i++) {
      try {
        const line = importLines[i];
        const product = line[productIndex];
        const pu = parseFloat(line[puIndex]);
        const fas = parseFloat(line[fasIndex]);
        const engagement = parseInt(line[engagementIndex]);
        const com = parseFloat(line[comIndex]);

        modifiedPrices.push({
          product,
          pu,
          fas,
          engagement,
          com
        } as IPrice)
      }
      catch (err) {
        console.error(err);
      }

    }

    this.setState({
      prices: modifiedPrices,
      showDeleteDialog: false,
      selectedIDs: [],
      showImport: false,
      importLines: null
    });
  }

  downloadDataToUser(data: string | Buffer, fileName = "export.csv") {
    let blob = null;
    if (typeof data === "string") {
      blob = new Blob([Buffer.from(data, "utf8")]);
    }
    else {
      blob = new Blob([data]);
    }
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }


  renderImportDialog() {
    const { importLines } = this.state;
    return <Dialog
      title={i18n.s('importProducts')}
      showOkButton={importLines !== null}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({ showImport: false, importLines: null })
      }}
      onOK={() => {
        this.importObjects();
      }}
    >
      <ImportCSV
        columns={['product', 'pu', 'fas', 'engagement', 'com']}
        onImportGenerated={(result: string[][]) => { this.setState({ importLines: result }) }}
        separator='\t'
      />
    </Dialog>
  }



  renderDeleteDialog() {
    const { selectedIDs } = this.state;
    return <Dialog
      title={i18n.s("deletePricelistConfirm")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          showDeleteDialog: false
        })
      }}
      onOK={async () => {
        showLoading(true);
        let failed = 0;
        for (var id of selectedIDs) {
          const ok = await api.pricelist.deletePricelist(id);
          if (!ok) {
            failed++;
          }
        }
        this.reloadObjects();
        if (failed) {
          showError(i18n.s("deletePricelistFailure"));
        }
        else {
          showSuccess(i18n.s('success'));
        }
        showLoading(false);
      }}
    >
      <div>
        <label>{i18n.s("deletePricelistConfirmString", [selectedIDs.length.toString()])}</label>
      </div>
    </Dialog>
  }


  renderObjectMod(): React.ReactNode {
    const { editPricelist, products, prices, selectedProducts, massPU, massFAS, massEngagement, massCOM } = this.state;
    if (editPricelist === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("editPricelist")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          editPricelist: null,
        });
      }}
      onOK={async () => {
        if (await this.savePricelist()) {
          this.setState({
            editPricelist: null,
          });
        }
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('pricelistName')}</label></div>
            <input autoComplete='off' type="text" value={editPricelist.name} placeholder={i18n.s("pricelistName")} onChange={(e) => {
              this.setState(
                {
                  editPricelist: {
                    ...editPricelist,
                    name: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
        </div>
        <h3>{i18n.s("priceListEntries")}</h3>

        {
          selectedProducts.length > 0
            ? <div className='mass-edit'>
              <h4>Modification en masse</h4>
              <table>
                <thead>
                  <tr>
                    <th>{i18n.s('pu')}</th>
                    <th>{i18n.s('fas')}</th>
                    <th>{i18n.s('priceEngagement')}</th>
                    <th>{i18n.s('com')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input type="text" placeholder={i18n.s('emptyIfNoChanges')} value={massPU}
                      onChange={(e) => {
                        this.setState({
                          massPU: e.currentTarget.value
                        })
                      }} /></td>
                    <td><input type="text" placeholder={i18n.s('emptyIfNoChanges')} value={massFAS}
                      onChange={(e) => {
                        this.setState({
                          massFAS: e.currentTarget.value
                        })
                      }} /></td>
                    <td><input type="text" placeholder={i18n.s('emptyIfNoChanges')} value={massEngagement}
                      onChange={(e) => {
                        this.setState({
                          massEngagement: e.currentTarget.value
                        })
                      }} /></td>
                    <td><input type="text" placeholder={i18n.s('emptyIfNoChanges')} value={massCOM}
                      onChange={(e) => {
                        this.setState({
                          massCOM: e.currentTarget.value
                        })
                      }} /></td>
                  </tr>
                </tbody>
              </table>
              <div className='buttons right'>
                <button className='success'
                  onClick={() => {
                    this.setState({
                      prices: prices.map((pr) => {
                        if (selectedProducts.includes(pr.product)) {
                          return {
                            ...pr,
                            pu: massPU && !isNaN(parseFloat(massPU)) ? parseFloat(massPU) : pr.pu,
                            fas: massFAS && !isNaN(parseFloat(massFAS)) ? parseFloat(massFAS) : pr.fas,
                            engagement: massEngagement && !isNaN(parseFloat(massEngagement)) ? parseFloat(massEngagement) : pr.engagement,
                            com: massCOM && !isNaN(parseFloat(massCOM)) ? parseFloat(massCOM) : pr.com
                          }
                        }
                        return { ...pr };
                      }),
                      selectedProducts: []
                    })
                  }}
                ><IoCheckmarkOutline /><label>{i18n.s('massChangePrices')}</label></button>
              </div>
            </div>
            : null
        }
        <div className='edit-object-form'>
          <div className='buttons'>
            <button onClick={() => { this.exportObjects() }}><RxUpload /> <label>{i18n.s("exportPrices")}</label></button>
            <button onClick={() => { this.setState({ showImport: true }) }}><RxDownload /> <label>{i18n.s("importPrices")}</label></button>
          </div>
          <table>
            <thead>
              <tr>
                <th><input type='checkbox'
                  checked={selectedProducts.length === products.length}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      this.setState({
                        selectedProducts: products.map(p => p.ref)
                      })
                    }
                    else {
                      this.setState({
                        selectedProducts: []
                      })
                    }
                  }}
                /></th>
                <th>{i18n.s('productName')}</th>
                <th>{i18n.s('pu')}</th>
                <th>{i18n.s('fas')}</th>
                <th>{i18n.s('priceEngagement')}</th>
                <th>{i18n.s('com')}</th>
              </tr>
            </thead>
            <tbody>
              {
                products.map((pro) => {
                  const price = prices.find(pri => pri.product === pro.ref);
                  if (!price) {
                    return null;
                  }
                  return <tr key={pro.ref}>
                    <td><input type='checkbox'
                      checked={selectedProducts.findIndex(p => p === pro.ref) > -1}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          this.setState({
                            selectedProducts: [...selectedProducts, pro.ref]
                          })
                        }
                        else {
                          this.setState({
                            selectedProducts: selectedProducts.filter(p => p !== pro.ref)
                          })
                        }
                      }}
                    /></td>
                    <td>{pro.name}</td>
                    <td><input type='number' value={price.pu} onChange={(e) => { this.onPriceEdit(price, "pu", Number.parseFloat(e.currentTarget.value)) }} /></td>
                    <td><input type='number' value={price.fas} onChange={(e) => { this.onPriceEdit(price, "fas", Number.parseFloat(e.currentTarget.value)) }} /></td>
                    <td><input type='number' value={price.engagement} onChange={(e) => { this.onPriceEdit(price, "engagement", Number.parseInt(e.currentTarget.value)) }} /></td>
                    <td><input type='number' value={price.com} onChange={(e) => { this.onPriceEdit(price, "com", Number.parseFloat(e.currentTarget.value)); }} /></td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </Dialog>
  }

  renderObjectRow(obj: IPricelist): React.ReactNode {
    const { selectedIDs } = this.state;

    const isSelected = selectedIDs.findIndex(suid => suid === obj.id) > -1;
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (obj.name.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        if (obj.id !== null && selectedIDs.indexOf(obj.id) !== -1) {
          this.setState({
            selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
          });
        }
        return null;
      }
    }

    return <tr key={obj.id} >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(e) => {
            if (obj.id === null)
              return;
            if (e.currentTarget.checked) {
              this.setState({
                selectedIDs: [
                  ...selectedIDs,
                  obj.id
                ]
              })
            }
            else {
              this.setState({
                selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
              })
            }
          }}
        />
      </td>
      <td><button onClick={async () => {
        if (obj.id) {
          this.setState({
            editPricelist: obj,
            prices: await this.loadAndMergePricesForCurrentPricelist(obj)
          })
        }
      }}><VscEdit /></button></td>
      <td >{obj.name}</td>
    </tr>
  }





  render() {
    const { pricelists, search, editPricelist, selectedIDs, showDeleteDialog, showImport } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("pricelist")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <div className='inline'>
              <button onClick={() => { this.reloadObjects() }} title={i18n.s('reload')}><VscRefresh /></button>
              <button className='success' onClick={() => { this.createNewObject() }} title={i18n.s("createPricelist")}><BsPlus /></button>
              <button className='danger' title={i18n.s("deleteProducts")} disabled={selectedIDs.length === 0} onClick={() => {
                this.setState({
                  showDeleteDialog: true
                })
              }}><AiOutlineDelete /></button>
            </div>

          </div>

          <table>
            <thead>
              <tr>
                <th style={{ width: "30px", textAlign: "center" }}>
                  <input
                    type="checkbox"
                    onClick={(e) => {
                      if (e.currentTarget.checked) {
                        this.setState({
                          selectedIDs: pricelists.filter(p => p.id).map(p => p.id)
                        })
                      }
                      else {
                        this.setState({
                          selectedIDs: []
                        })
                      }
                    }}
                  />
                </th>
                <th style={{ width: "30px", textAlign: "center" }}></th>
                <th onClick={() => { this.sortObjectsBy("name") }}>{i18n.s("pricelistName")}</th>
              </tr>
            </thead>
            <tbody>
              {
                pricelists.map((obj) => {
                  return this.renderObjectRow(obj)
                })
              }
            </tbody>
          </table>

          {
            editPricelist !== null ? this.renderObjectMod() : null
          }

          {
            showDeleteDialog ? this.renderDeleteDialog() : null
          }


          {
            showImport ? this.renderImportDialog() : null
          }



        </div>
      </Fragment>

    );
  }
}

export default PricelistManagement;