import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';
import { IPrice, IPricelist } from '../../apitypes/index';

export default class PricelistModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getPricelists(): Promise<IPricelist[]> {
    let response = await GetAPI(`${this.baseUri}/pricelists`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getPricelist(id: number): Promise<IPricelist | null> {
    let response = await GetAPI(`${this.baseUri}/pricelists/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async getPrices(id: number): Promise<IPrice[]> {
    let response = await GetAPI(`${this.baseUri}/pricelists/${id}/prices`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }



  async createPricelist(pricelist: IPricelist, prices: IPrice[]): Promise<IPricelist | null> {
    let response = await PostAPI(`${this.baseUri}/pricelists`, this.token, pricelist, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      await this.setPricelistPrices(msg.data.id, prices);
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async updatePricelist(id: number, pricelist: IPricelist, prices: IPrice[]): Promise<IPricelist | null> {
    let response = await PutAPI(`${this.baseUri}/pricelists/${id}`, this.token, pricelist, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      await this.setPricelistPrices(msg.data.id, prices);
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async setPricelistPrices(pricelist: number, prices: IPrice[]): Promise<IPrice | null> {
    let response = await PostAPI(`${this.baseUri}/pricelists/${pricelist}/prices`, this.token, prices, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async deletePricelist(pricelist: number): Promise<boolean> {
    let response = await DeleteAPI(`${this.baseUri}/pricelists/${pricelist}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }
}




