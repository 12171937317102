import { IReseller } from "../../apitypes";
import { api, showError, showSuccess, showInfo, showLoading } from "../../sharedInterfaces";
import Dialog from "../../comps/dialog/Dialog";
import i18n from "../../i18n/I18n";
import { useEffect, useState } from "react";
import { RxPencil1 } from 'react-icons/rx';


interface ICloneProxmoxTemplateProps {
  template: any,
  onSave: Function,
  onCancel: Function,
  resellers: IReseller[]
}

export default function CloneProxmoxTemplate(props: ICloneProxmoxTemplateProps) {
  const [config, setConfig] = useState<any | null>(null);
  const [reseller, setReseller] = useState<IReseller | null>(null);
  const [mac, setMac] = useState<string>('00:00:00:00:00:00');
  const [ip, setIP] = useState<string>('0.0.0.0/24');
  const [gateway, setGateway] = useState<string>('0.0.0.0');
  const [newVMID, setNewVMID] = useState<number>(0);
  const [diskSize, setDiskSize] = useState<number>(0);

  useEffect(() => {
    (async () => {
      //load template current config
      if (props.template && props.template.vmid) {
        const templateConfig = await api.baremetal.getBaremetalVMConfig(props.template.baremetal, props.template.vmid);
        setNewVMID(await api.baremetal.getBaremetalAvailableVMID(props.template.baremetal));
        setConfig({
          name: `Clone`,
          template: 0,
          cores: templateConfig.cores,
          memory: templateConfig.memory,
          ipconfig0: templateConfig.ipconfig0,
          net0: templateConfig.net0,
          sshkeys: templateConfig.sshkeys,
          nameserver: "1.1.1.1",
          searchdomain: "9.9.9.9",
          onboot: 1,
          tags: ''
        });
      }
      else {
        setConfig(null);
      }
    })();
  }, []);



  return <Dialog
    title={i18n.s("cloneProxmoxTemplate")}
    showOkButton={true}
    showCancelButton={true}
    showCloseButton={false}
    onCancel={() => {
      props.onCancel();
    }}
    onOK={async () => {
      if (props.template && config) {
        props.onSave(props.template.baremetal, props.template.vmid, newVMID, diskSize > 0 ? `+${diskSize}G` : null, config);
      }
    }}
  >
    <div className='edit-object'>
      <div className='edit-object-form'>
        {
          config
            ? <>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmNewVMID')}</label></div>
                <input autoComplete='off' type="text" value={newVMID} readOnly={true} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmName')}</label></div>
                <input autoComplete='off' type="text" value={config.name} placeholder={i18n.s("vmName")} onChange={(e) => {
                  if (e.currentTarget.value.match(/^([0-9]|[a-z]|[A-Z]|[-.])*$/)) {
                    setConfig({
                      ...config,
                      name: e.currentTarget.value
                    })
                  }
                }} />
              </div>
              <div className="row input-with-label">
                <div><RxPencil1 /> <label>{i18n.s('reseller')}</label></div>
                <select
                  value={reseller ? reseller.id : ''}
                  onChange={(e) => {
                    const res = props.resellers.find(r => r.id === parseInt(e.currentTarget.value)) || null;
                    setReseller(res);
                    setConfig({
                      ...config,
                      tags: res ? `rid-${res.id}` : ''
                    })
                  }}
                >
                  <option value=''></option>
                  {
                    props.resellers.map((res) => {
                      return <option key={res.id} value={res.id}>{res.company}</option>
                    })
                  }
                </select>
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmCPU')}</label></div>
                <input type="number" min={1} step={1} value={config.cores} onChange={(e) => {
                  setConfig({
                    ...config,
                    cores: Number.parseInt(e.currentTarget.value)
                  })
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmMemory')}</label></div>
                <input type="number" min={512} step={512} value={config.memory} onChange={(e) => {
                  let mem = Number.parseInt(e.currentTarget.value);
                  mem = Math.max(mem, 512);
                  setConfig({
                    ...config,
                    memory: mem
                  })
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmAddDiskSize')}</label></div>
                <input type="number" min={0} step={1} value={diskSize} onChange={(e) => {
                  let ds = Number.parseInt(e.currentTarget.value);
                  setDiskSize(ds);
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmIP')}</label></div>
                <input type="text" placeholder="192.168.5.150/24" value={ip} onChange={(e) => {
                  setIP(e.currentTarget.value);
                  setConfig({
                    ...config,
                    ipconfig0: `ip=${e.currentTarget.value},gw=${gateway}`
                  })
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmIPGateway')}</label></div>
                <input type="text" placeholder="192.168.5.1" value={gateway} onChange={(e) => {
                  setGateway(e.currentTarget.value);
                  setConfig({
                    ...config,
                    ipconfig0: `ip=${ip},gw=${e.currentTarget.value}`
                  })
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmMacAddress')}</label></div>
                <input type="text" value={mac} onChange={(e) => {
                  setMac(e.currentTarget.value.substring(0, 17));
                  setConfig({
                    ...config,
                    net0: `model=virtio,bridge=vmbr0,firewall=1,macaddr=${e.currentTarget.value.substring(0, 17)}`
                  })
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmSSHKey')}</label></div>
                <textarea value={config.sshkeys ? decodeURIComponent(config.sshkeys) : ''} onChange={(e) => {
                  setConfig({
                    ...config,
                    sshkeys: encodeURIComponent(e.currentTarget.value)
                  })
                }}></textarea>
              </div>
            </>
            : null
        }
      </div>
    </div>
  </Dialog>
}