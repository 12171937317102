import { IReseller } from "../../apitypes";
import { api, showError, showSuccess, showInfo, showLoading } from "../../sharedInterfaces";
import Dialog from "../../comps/dialog/Dialog";
import i18n from "../../i18n/I18n";
import { useEffect, useState } from "react";
import { RxPencil1 } from 'react-icons/rx';


interface IEditProxmoxVMProps {
  vm: any,
  onSave: Function,
  onCancel: Function,
  resellers: IReseller[]
}

export default function EditProxmoxVM(props: IEditProxmoxVMProps) {
  const [config, setConfig] = useState<any | null>(null);
  const [reseller, setReseller] = useState<IReseller | null>(null);

  useEffect(() => {
    (async () => {
      //load current config
      if (props.vm) {
        const reseller = props.vm.reseller ? props.resellers.find(r => r.id === parseInt(props.vm.reseller)) || null : null;
        setReseller(reseller);
        const vmConfig = await api.baremetal.getBaremetalVMConfig(props.vm.baremetal, props.vm.vmid);
        setConfig({
          name: vmConfig.name,
          template: 0,
          cores: vmConfig.cores,
          memory: vmConfig.memory
        });
      }
      else {
        setConfig(null);
      }
    })();
  }, []);



  return <Dialog
    title={i18n.s("editProxmoxVM")}
    showOkButton={true}
    showCancelButton={true}
    showCloseButton={false}
    onCancel={() => {
      props.onCancel();
    }}
    onOK={async () => {
      if (props.vm && config) {
        props.onSave(props.vm.baremetal, props.vm.vmid, config);
      }
    }}
  >
    <div className='edit-object'>
      <div className='edit-object-form'>
        {
          config
            ? <>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmName')}</label></div>
                <input autoComplete='off' type="text" value={config.name} placeholder={i18n.s("vmName")} onChange={(e) => {
                  if (e.currentTarget.value.match(/^([0-9]|[a-z]|[A-Z]|[-.])*$/)) {
                    setConfig({
                      ...config,
                      name: e.currentTarget.value
                    })
                  }
                }} />
              </div>
              <div className="row input-with-label">
                <div><RxPencil1 /> <label>{i18n.s('reseller')}</label></div>
                <select
                  value={reseller ? reseller.id : ''}
                  onChange={(e) => {
                    const res = props.resellers.find(r => r.id === parseInt(e.currentTarget.value)) || null;
                    setReseller(res);
                    setConfig({
                      ...config,
                      tags: res ? `rid-${res.id}` : ''
                    })
                  }}
                >
                  <option value=''></option>
                  {
                    props.resellers.map((res) => {
                      return <option key={res.id} value={res.id}>{res.company}</option>
                    })
                  }
                </select>
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmCPU')}</label></div>
                <input type="number" min={1} step={1} value={config.cores} onChange={(e) => {
                  setConfig({
                    ...config,
                    cores: Number.parseInt(e.currentTarget.value)
                  })
                }} />
              </div>
              <div className='row input-with-label'>
                <div><RxPencil1 /> <label>{i18n.s('vmMemory')}</label></div>
                <input type="number" min={512} step={512} value={config.memory} onChange={(e) => {
                  let mem = Number.parseInt(e.currentTarget.value);
                  mem = Math.max(mem, 512);
                  setConfig({
                    ...config,
                    memory: mem
                  })
                }} />
              </div>
            </>
            : null
        }
      </div>
    </div>
  </Dialog>
}