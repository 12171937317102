import React, { useCallback, useEffect, useState } from "react";
import { api } from '../../sharedInterfaces';
import '../../styles/tasklist.scss';
import i18n from "../../i18n/I18n";
import { EPrivileges, ITask, hasAnyPrivileges } from "../../apitypes";
import moment from 'moment';

import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";
import { TbLoaderQuarter } from "react-icons/tb";

interface IProps {
}

const minHeight = 80;
export default function TaskList(props: IProps) {
  const [initialCursorPosition, setInitialCursorPosition] = useState([0, 0]);
  const [initialHeight, setInitialHeight] = useState(80);
  const [height, setHeight] = useState(80);
  const [isResizing, setIsResizing] = useState(false);
  const [tasks, setTasks] = useState<ITask[]>([]);

  useEffect(() => {
    updateTasks();
  }, [])


  useEffect(() => {
    let timer: any = null;
    timer = setTimeout(() => {
      updateTasks();
    }, 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [tasks])

  //Callback called by mousemove, with initial cursorposition and initialheigh stored.
  const mouseMoveEvent = useCallback((ev: MouseEvent) => {
    if (isResizing) {
      const moveY = ev.clientY - initialCursorPosition[1];
      const newHeight = Math.max(minHeight, initialHeight - moveY);
      setHeight(newHeight);
    }
  }, [isResizing])

  //Add event handler each time we resize !
  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', mouseMoveEvent);
    }
    else {
      window.removeEventListener('mousemove', mouseMoveEvent);
    }
    return () => {
      window.removeEventListener('mousemove', mouseMoveEvent);
    }
  }, [isResizing]);



  const updateTasks = async () => {
    const ts = await api.tasks.getTasks(moment().subtract(7, 'day').unix());
    setTasks(ts);
  }


  return <div className="tasklist" style={{ height: `${height}px`, minHeight: `${height}px` }}>
    <hr onMouseDown={(ev) => {
      setInitialHeight(height);
      setInitialCursorPosition([ev.clientX, ev.clientY]);
      setIsResizing(true);
    }} onMouseUp={() => {
      setIsResizing(false);
    }} />
    <div className="tasks">
      <table>
        <thead>
          <tr>
            <th>{i18n.s('taskDate')}</th>
            <th>{i18n.s('taskStatus')}</th>
            <th>{i18n.s('taskAction')}</th>
            {
              hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
                ? <th>{i18n.s('taskData')}</th>
                : null
            }
            <th>{i18n.s('taskError')}</th>
          </tr>
        </thead>
        <tbody>
          {
            tasks.map((t) => {
              return <tr key={t.id}>
                <td>{moment.unix(t.date).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td className="center">
                  {
                    t.done === 0
                      ? <div className="rotate"><TbLoaderQuarter /></div>
                      : t.success
                        ? <div className="success"> <AiFillCheckCircle /></div>
                        : <div className="danger"> <AiFillExclamationCircle /></div>
                  }
                </td>
                <td>{t.action}</td>
                {
                  hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
                    ? <td>{t.data}</td>
                    : null
                }
                <td>{t.error ? t.error : ''}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  </div>;
}