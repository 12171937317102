import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/cgv.scss';
import { IPageProps } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import { hasAnyPrivileges, EPrivileges } from '../../apitypes/index';
import { IUser, ICGV } from '../../apitypes/index';
import i18n from '../../i18n/I18n';
import Dialog from '../../comps/dialog/Dialog';
import { RxPencil1 } from 'react-icons/rx';
import { BsPlus } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import Markdown from 'markdown-to-jsx'

interface IState {
  user: IUser | null,
  cgvs: ICGV[],
  editObject: ICGV | null
}

class CGV extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      user: null,
      cgvs: [],
      editObject: null,
    }
  }


  async componentDidMount(): Promise<void> {

    if (api.currentUser !== null) {
      showLoading(true);
      this.setState({
        user: await api.user.getUser(api.currentUser?.id)
      });
      showLoading(false);
      await this.loadCGVs();
    }
  }

  async loadCGVs(): Promise<void> {

    showLoading(true);
    this.setState({
      cgvs: await api.cgv.getCGVs(),
      editObject: null
    });
    showLoading(false);
  }


  async deleteCGV(id: string): Promise<void> {
    showLoading(true);
    if (await api.cgv.deleteCGV(id)) {
      showSuccess(i18n.s("success"));
      await this.loadCGVs();
      showLoading(false);
    }
    else {
      showError(i18n.s("erreor"));
    }
  }

  rendereditObject(): React.ReactNode {
    const { editObject, } = this.state;
    if (editObject === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("editCGV")}
      width='70%'
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          editObject: null,
        });
      }}
      onOK={async () => {
        if (editObject.id.trim() === '') {
          showError(i18n.s("noCGVID"));
          return;
        }
        if (editObject.title.trim() === '') {
          showError(i18n.s("noCGVTitle"));
          return;
        }
        if (editObject.content.trim() === "") {
          showError(i18n.s("noCGVContent"));
          return;
        }

        showLoading(true);
        if (await api.cgv.createOrUpdateCGV(editObject)) {
          showSuccess(i18n.s("CGVEdited"));
          this.loadCGVs();
        }
        else {
          showError(i18n.s("cgvCreateError"));
        }
        showLoading(false);
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('cgvID')}</label></div>
            <input autoComplete='off' type="text" value={editObject.id} placeholder={i18n.s("cgvID")} onChange={(e) => {
              this.setState(
                {
                  editObject: {
                    ...editObject,
                    id: e.currentTarget.value.replace(/\s/g, "_")
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('cgvTitle')}</label></div>
            <input autoComplete='off' type="text" value={editObject.title} placeholder={i18n.s("cgvTitle")} onChange={(e) => {
              this.setState(
                {
                  editObject: {
                    ...editObject,
                    title: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('cgvContent')}</label></div>
            <textarea value={editObject.content} onChange={(e) => {
              this.setState(
                {
                  editObject: {
                    ...editObject,
                    content: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
        </div>
      </div>
    </Dialog>
  }

  render() {
    const { user, cgvs, editObject } = this.state;
    return (
      <div className='management cgvs'>
        {
          user?.cgvSeen === null
            ? <Dialog
              title={i18n.s("cgvModified")}
              showOkButton={true}
              onOK={async () => {
                showLoading(true);
                if (await api.user.updateUser(user.id, {
                  ...user,
                  cgvSeen: Math.round(new Date().getTime() / 1000)
                }) === null) {
                  showError(i18n.s("error"));
                }
                else {
                  showSuccess(i18n.s("success"));
                }
                this.setState({
                  user: await api.user.getUser(user.id)
                });
                showLoading(false);
              }}
            >
              <div>
                <label>{i18n.s("pleaseReadCGV")}</label>
              </div>
            </Dialog>
            : null
        }

        <div>
          <h1>{i18n.s("CGV")}</h1>
          {
            hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
              ? <button className='success' title={i18n.s("createCGV")} onClick={() => {
                this.setState({
                  editObject: {
                    id: "",
                    title: i18n.s("newCGV"),
                    content: ''
                  }
                })
              }}><BsPlus /></button>
              : null
          }

          {
            cgvs.map((cgv) => {
              return <div key={cgv.id} className='cgv'>
                <div className='cgv-title'>
                  <span className='buttons'>
                    {
                      hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
                        ? <Fragment>
                          <button title={i18n.s('edit')} onClick={() => { this.setState({ editObject: { ...cgv } }) }}><RxPencil1 /></button>
                          <button className='danger' title={i18n.s('delete')} onClick={() => {
                            if (cgv.id && window.confirm(i18n.s("deleteCGVConfirmation"))) {
                              this.deleteCGV(cgv.id);
                            }
                          }}><AiOutlineDelete /></button>
                        </Fragment>
                        : null
                    }
                  </span>
                  <span>{cgv.title}</span>
                </div>
                <div className='cgv-content'>
                  <Markdown>{cgv.content}</Markdown>
                </div>
              </div>
            })
          }

        </div>

        {
          editObject !== null
            ? this.rendereditObject()
            : null
        }


      </div >
    );
  }
}

export default CGV;